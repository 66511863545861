export type ILinks = { href: string; name: string; icon: any }
export type ISelect = {
  value: string
  label: string
}

type IDefaultSchedule = {
  pro_schedule_id: number
  day: string
  available_times: {
    time_from: string
    time_to: string
  }[]
  timezone: string
  created_at: any
  updated_at: any
}

export type IUserTransactions = {
  status: 'PENDING' | 'SUCCESS' | 'FAILED' | ''
  payment_type: 'MEETING' | 'TOPUP' | 'RESOURCE' | ''
  transaction_type: 'CREDIT' | 'DEBIT' | ''
  page: number
}

export type IProfileModal = {
  showPro?: boolean
  showCredentials?: boolean
  showSocials: boolean
  showExperience: boolean
  showEducation: boolean
  showMore: boolean
}

export type IBroadcastData = {
  title: string
  description: string
  broadcast_type: 'VIDEO' | 'AUDIO' | null
  payment_type: 'PAID' | 'FREE' | null
  fee?: number
  cover: Blob
  meeting_date: string
  meeting_start_time: string
  meeting_end_time: string
  available_tickets?: number
  hosts: {
    user_id: number
    email: string
  }[]
}

export const genderArray = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non Binary', value: 'non-binary' },
  { label: 'Others', value: 'others' },
]

export const participantsArray: ISelect[] = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
]

export const durationArray: ISelect[] = [
  {
    value: '20',
    label: '20',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '45',
    label: '45',
  },
  {
    value: '60',
    label: '60',
  },
]

export const interestArray: ISelect[] = [
  { label: 'Data Science', value: 'Data Science' },
  { label: 'Product Management', value: 'Product Management' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Product Research', value: 'Product Research' },
  { label: 'Product', value: 'Product' },
  { label: 'Software Development', value: 'Software Development' },
]

export const months: ISelect[] = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',
    value: '07',
  },
  {
    label: 'August',
    value: '08',
  },
  {
    label: 'September',
    value: '09',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
]

export const years: ISelect[] = [
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2018',
    value: '2018',
  },
  {
    label: '2017',
    value: '2017',
  },
  {
    label: '2016',
    value: '2016',
  },
  {
    label: '2015',
    value: '2015',
  },
  {
    label: '2014',
    value: '2014',
  },
  {
    label: '2013',
    value: '2013',
  },
  {
    label: '2012',
    value: '2012',
  },
  {
    label: '2011',
    value: '2011',
  },
  {
    label: '2010',
    value: '2010',
  },
  {
    label: '2009',
    value: '2009',
  },
  {
    label: '2008',
    value: '2008',
  },
  {
    label: '2007',
    value: '2007',
  },
  {
    label: '2006',
    value: '2006',
  },
  {
    label: '2005',
    value: '2005',
  },
  {
    label: '2004',
    value: '2004',
  },
  {
    label: '2003',
    value: '2003',
  },
  {
    label: '2002',
    value: '2002',
  },
  {
    label: '2001',
    value: '2001',
  },
  {
    label: '2000',
    value: '2000',
  },
  {
    label: '1999',
    value: '1999',
  },
  {
    label: '1998',
    value: '1998',
  },
  {
    label: '1997',
    value: '1997',
  },
  {
    label: '1996',
    value: '1996',
  },
  {
    label: '1995',
    value: '1995',
  },
  {
    label: '1994',
    value: '1994',
  },
  {
    label: '1993',
    value: '1993',
  },
  {
    label: '1992',
    value: '1992',
  },
  {
    label: '1991',
    value: '1991',
  },
  {
    label: '1990',
    value: '1990',
  },
  {
    label: '1989',
    value: '1989',
  },
  {
    label: '1988',
    value: '1988',
  },
  {
    label: '1987',
    value: '1987',
  },
  {
    label: '1986',
    value: '1986',
  },
  {
    label: '1985',
    value: '1985',
  },
  {
    label: '1984',
    value: '1984',
  },
  {
    label: '1983',
    value: '1983',
  },
  {
    label: '1982',
    value: '1982',
  },
  {
    label: '1981',
    value: '1981',
  },
  {
    label: '1980',
    value: '1980',
  },
  {
    label: '1979',
    value: '1979',
  },
  {
    label: '1978',
    value: '1978',
  },
  {
    label: '1977',
    value: '1977',
  },
  {
    label: '1976',
    value: '1976',
  },
  {
    label: '1975',
    value: '1975',
  },
  {
    label: '1974',
    value: '1974',
  },
  {
    label: '1973',
    value: '1973',
  },
  {
    label: '1972',
    value: '1972',
  },
  {
    label: '1971',
    value: '1971',
  },
  {
    label: '1970',
    value: '1970',
  },
]

export const degrees: ISelect[] = [
  {
    label: 'Professional Certificate',
    value: 'Professional Certificate',
  },
  {
    label: 'Undergraduate Degree',
    value: 'Undergraduate Degree',
  },
  {
    label: 'Transfer Degree',
    value: 'Transfer Degree',
  },
  {
    label: 'Associate Degree',
    value: 'Associate Degree',
  },
  {
    label: 'Bachelor Degree',
    value: 'Bachelor Degree',
  },
  {
    label: 'Graduate Degree',
    value: 'Graduate Degree',
  },
  {
    label: 'Master Degree',
    value: 'Master Degree',
  },
  {
    label: 'Doctoral Degree',
    value: 'Doctoral Degree',
  },
  {
    label: 'Professional Degree',
    value: 'Professional Degree',
  },
  {
    label: 'Specialist Degree',
    value: 'Specialist Degree',
  },
]

export const defaultSchedules: IDefaultSchedule[] = [
  {
    pro_schedule_id: 1,
    day: 'SUNDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'MONDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'TUESDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'WEDNESDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'THURSDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'FRIDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
  {
    pro_schedule_id: 1,
    day: 'SATURDAY',
    available_times: [
      {
        time_from: '08:00',
        time_to: '17:00',
      },
    ],
    timezone: 'Africa/Lagos',
    created_at: null,
    updated_at: null,
  },
]

export const experienceData: {
  company: string
  role: string
  is_present: boolean
  start_date: string
  end_date: string
}[] = [
  {
    company: 'Autopilot',
    role: 'Lead Product Designer',
    is_present: true,
    start_date: '01/2021',
    end_date: '01/2022',
  },
  {
    company: 'Autopilot',
    role: 'Lead Product Designer',
    is_present: false,
    start_date: '01/2021',
    end_date: '01/2022',
  },
  {
    company: 'Autopilot',
    role: 'Lead Product Designer',
    is_present: true,
    start_date: '01/2021',
    end_date: '01/2022',
  },
  {
    company: 'Autopilot',
    role: 'Lead Product Designer',
    is_present: false,
    start_date: '01/2021',
    end_date: '01/2022',
  },
]

export const educationData: {
  school: string
  degree: string
  is_present: boolean
  start_date: string
  end_date: string
}[] = [
  {
    school: 'Autopilot',
    degree: 'Lead Product Designer',
    is_present: true,
    start_date: '01/2021',
    end_date: '01/2022',
  },
  {
    school: 'Autopilot',
    degree: 'Lead Product Designer',
    is_present: false,
    start_date: '01/2021',
    end_date: '01/2022',
  },
]

export const skills = [
  'Product Design',
  'Product Design',
  'Product Design',
  'Product Design',
  'Product Design',
]

export const { linkedin, twitter, facebook, instagram, behance } = {
  linkedin: '/',
  twitter: '/',
  facebook: '/',
  instagram: '/',
  behance: '/',
}
