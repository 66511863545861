import http from '@/utils/axiosInstance'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Router from 'next/router'
import { getCookie, setCookie } from '../../../utils/session'

import { GetPaymentTypes } from '../payment'
import { GetUserNotifications } from '../settings/notification'
import { GetUserWithdrawalMethods } from '../settings/withdrawals'
import { GetEducation } from '../userData/education'
import { GetExperience } from '../userData/experience'
import { GetSocials } from '../userData/socials'
import { GetUserData } from '../userData/updateUser'
import { errorMessage, successMessage } from '../utils/generalMessage'
import { GetCountries, GetCurrencies, GetExchangeRates } from '../utils/miscellaneous'

import { authUser, logoutUser } from './userAuth'

interface IUserState {
  signInData: {}
  signInStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  signInError: string

  signOutStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  signOutError: string
}

const initialState: IUserState = {
  signInData: {},
  signInStatus: 'idle',
  signInError: '',

  signOutStatus: 'idle',
  signOutError: '',
}

export const loginUser = createAsyncThunk('login/loginUser', async (login: Object, thunkAPI) => {
  try {
    const response = await http.post('/auth/signin', login)
    let data = response.data

    if (response.status < 400) {
      setCookie('access_token', data.access_token)
      thunkAPI.dispatch(authUser())
      // thunkAPI.dispatch(successMessage(data.message))

      thunkAPI.dispatch(GetUserData())
      thunkAPI.dispatch(GetSocials())
      thunkAPI.dispatch(GetExperience())
      thunkAPI.dispatch(GetEducation())
      thunkAPI.dispatch(GetPaymentTypes())
      thunkAPI.dispatch(GetCountries())
      thunkAPI.dispatch(GetCurrencies())
      thunkAPI.dispatch(GetExchangeRates())
      thunkAPI.dispatch(GetUserWithdrawalMethods(1))
      thunkAPI.dispatch(
        GetUserNotifications({
          status: null,
          page: 1,
        })
      )

      return { ...data, login }
    } else {
      thunkAPI.dispatch(errorMessage(data.message))
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e) {
    thunkAPI.dispatch(errorMessage(e.response.data.message))
    return thunkAPI.rejectWithValue(e?.response?.data.message)
  }
})

export const signOutUser = createAsyncThunk('login/signOutUser', async (_, thunkAPI) => {
  const token = getCookie('access_token', { req: {} })
  try {
    const response = await http.post('auth/signout', token)
    let data = response.data

    if (response.status < 400) {
      thunkAPI.dispatch(logoutUser())
      Router.reload()
      Router.push('/')
      thunkAPI.dispatch(successMessage(data.message))
      return { ...data }
    } else {
      thunkAPI.dispatch(errorMessage(data.message))
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e) {
    thunkAPI.dispatch(errorMessage(e.response.data.message))
    return thunkAPI.rejectWithValue(e?.response?.data.message)
  }
})

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.signInStatus = 'succeeded'
      state.signInData = payload.login
    }),
      builder.addCase(loginUser.pending, state => {
        state.signInStatus = 'pending'
      }),
      builder.addCase(loginUser.rejected, (state, { error }) => {
        state.signInStatus = 'failed'
        state.signInError = error.message
      })

    builder.addCase(signOutUser.fulfilled, state => {
      state.signOutStatus = 'succeeded'
    }),
      builder.addCase(signOutUser.pending, state => {
        state.signOutStatus = 'pending'
      }),
      builder.addCase(signOutUser.rejected, (state, { error }) => {
        state.signOutStatus = 'failed'
        state.signOutError = error.message
      })
  },
})

export default loginSlice.reducer
