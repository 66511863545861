import S from './Switch.module.scss'

const Check = ({ checked, onClick, name }: { checked: any; name: string; onClick: any }) => {
  return (
    <label className={`${checked ? S.checked : ''} ${S.check}`}>
      <input hidden name={name} type="checkbox" value={checked} onClick={onClick} />
      <span />
    </label>
  )
}

export default Check
