import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
// import { errorMessage, successMessage } from './utils/generalMessage'

interface IPayment {
  paymentData: []
  paymentStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  paymentError: string

  paymentInitData: any
  paymentInitStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  paymentInitError: string
}

const initialState: IPayment = {
  paymentData: [],
  paymentStatus: 'idle',
  paymentError: '',

  paymentInitData: '',
  paymentInitStatus: 'idle',
  paymentInitError: '',
}

export const GetPaymentTypes = createAsyncThunk('payment/getPayment', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/payment/types`).then(response => response.data?.payment_types)
})

export const PaymentInit = createAsyncThunk(
  'payment/IpaymentInit',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/payment/init`, formData)
      let data = response.data

      if (response.status < 400) {
        return { ...data }
      } else {
        // thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      // thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetPaymentTypes.fulfilled, (state, { payload }) => {
      state.paymentStatus = 'succeeded'
      state.paymentError = ''
      state.paymentData = payload
    }),
      builder.addCase(GetPaymentTypes.pending, state => {
        state.paymentStatus = 'pending'
      }),
      builder.addCase(GetPaymentTypes.rejected, (state, { error }) => {
        state.paymentStatus = 'failed'
        state.paymentError = error.message
      }),
      builder.addCase(PaymentInit.fulfilled, (state, { payload }) => {
        state.paymentInitStatus = 'succeeded'
        state.paymentInitError = ''
        state.paymentInitData = payload?.clientSecret
      }),
      builder.addCase(PaymentInit.pending, state => {
        state.paymentInitStatus = 'pending'
      }),
      builder.addCase(PaymentInit.rejected, (state, { error }) => {
        state.paymentInitStatus = 'failed'
        state.paymentInitError = error.message
      })
  },
})

export default Payment.reducer
