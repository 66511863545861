import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

interface IUser {
  addSocialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  addSocialsError: string

  deleteSocialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  deleteSocialsError: string

  socialsData: any
  socialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  socialsError: string
}

const initialState: IUser = {
  addSocialsStatus: 'idle',
  addSocialsError: '',

  deleteSocialsStatus: 'idle',
  deleteSocialsError: '',

  socialsData: {},
  socialsStatus: 'idle',
  socialsError: '',
}

export const AddSocials = createAsyncThunk(
  'socials/addSocials',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/socialmedia/update`, formData)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetSocials())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteSocials = createAsyncThunk(
  'socials/deleteSocials',
  async (item: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.delete(`${baseUrl}/socialmedia/remove?item=${item}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetSocials())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetSocials = createAsyncThunk('socials/getSocials', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/socialmedia/get`).then(response => response.data)
})

export const Socials = createSlice({
  name: 'socials',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AddSocials.fulfilled, state => {
      state.addSocialsStatus = 'succeeded'
      state.addSocialsError = ''
    }),
      builder.addCase(AddSocials.pending, state => {
        state.addSocialsStatus = 'pending'
      }),
      builder.addCase(AddSocials.rejected, (state, { error }) => {
        state.addSocialsStatus = 'failed'
        state.addSocialsError = error.message
      })

    builder.addCase(DeleteSocials.fulfilled, state => {
      state.deleteSocialsStatus = 'succeeded'
      state.deleteSocialsError = ''
    }),
      builder.addCase(DeleteSocials.pending, state => {
        state.deleteSocialsStatus = 'pending'
      }),
      builder.addCase(DeleteSocials.rejected, (state, { error }) => {
        state.deleteSocialsStatus = 'failed'
        state.deleteSocialsError = error.message
      })

    builder.addCase(GetSocials.fulfilled, (state, { payload }) => {
      state.socialsStatus = 'succeeded'
      state.socialsError = ''
      state.socialsData = payload.socialMediaLinks[0]
    }),
      builder.addCase(GetSocials.pending, state => {
        state.socialsStatus = 'pending'
      }),
      builder.addCase(GetSocials.rejected, (state, { error }) => {
        state.socialsStatus = 'failed'
        state.socialsError = error.message
      })
  },
})

export default Socials.reducer
