import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { errorMessage } from '../utils/generalMessage'

interface INotifications {
  notificationChannelData: any
  notificationChannelStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  notificationChannelError: string

  userNotificationSettingsData: Array<{
    id: number
    name: string
    description: string
    actions: Array<{ id: number; active: boolean }>
  }>
  userNotificationSettingsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userNotificationSettingsError: string

  updateNotificationSettingsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateNotificationSettingsError: string

  userNotificationData: any
  userNotificationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userNotificationError: string

  markNotificationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  markNotificationError: string
}

const initialState: INotifications = {
  notificationChannelStatus: 'idle',
  notificationChannelError: '',
  notificationChannelData: [],

  userNotificationSettingsStatus: 'idle',
  userNotificationSettingsError: '',
  userNotificationSettingsData: [],

  updateNotificationSettingsStatus: 'idle',
  updateNotificationSettingsError: '',

  userNotificationStatus: 'idle',
  userNotificationError: '',
  userNotificationData: [],

  markNotificationStatus: 'idle',
  markNotificationError: '',
}

export const GetNoticationChannels = createAsyncThunk(
  'notifications/getNoticationChannels',
  async () => {
    setAuthorizationHeader()
    return await http.get(`${baseUrl}/notification/channels`).then(response => response.data)
  }
)

export const GetUserNotificationSettings = createAsyncThunk(
  'notifications/getUserNotificationSettings',
  async () => {
    setAuthorizationHeader()
    return await http.get(`${baseUrl}/setting/notification`).then(response => response.data)
  }
)

export const GetUserNotifications = createAsyncThunk(
  'notifications/getUserNotifications',
  async ({ status, page }: { status: 'read' | 'unread'; page: number }) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/notification?status=${status}&per_page=10&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const UpdateNotificationSettings = createAsyncThunk(
  'notification/updateNotificationSettings',
  async (
    {
      channel,
      action,
      active,
    }: {
      channel: Number
      action: Number
      active: boolean
    },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`${baseUrl}/setting/notification`, {
        channel,
        action,
        active,
      })
      let data = response.data

      if (response.status < 400) {
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const MarkNotificationRead = createAsyncThunk(
  'notification/MarkNotificationRead',
  async (
    {
      status,
      id,
    }: {
      status: 'unread' | 'read'
      id: string
    },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`${baseUrl}/notification`, {
        status,
        id,
      })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(
          GetUserNotifications({
            status: null,
            page: 1,
          })
        )
        // thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetNoticationChannels.fulfilled, (state, { payload }) => {
      state.notificationChannelStatus = 'succeeded'
      state.notificationChannelError = ''
      state.notificationChannelData = payload
    }),
      builder.addCase(GetNoticationChannels.pending, state => {
        state.notificationChannelStatus = 'pending'
      }),
      builder.addCase(GetNoticationChannels.rejected, (state, { error }) => {
        state.notificationChannelStatus = 'failed'
        state.notificationChannelError = error.message
      })

    builder.addCase(GetUserNotificationSettings.fulfilled, (state, { payload }) => {
      state.userNotificationSettingsStatus = 'succeeded'
      state.userNotificationSettingsError = ''
      state.userNotificationSettingsData = payload
    }),
      builder.addCase(GetUserNotificationSettings.pending, state => {
        state.userNotificationSettingsStatus = 'pending'
      }),
      builder.addCase(GetUserNotificationSettings.rejected, (state, { error }) => {
        state.userNotificationSettingsStatus = 'failed'
        state.userNotificationSettingsError = error.message
      })

    builder.addCase(GetUserNotifications.fulfilled, (state, { payload }) => {
      state.userNotificationStatus = 'succeeded'
      state.userNotificationError = ''
      state.userNotificationData = payload
    }),
      builder.addCase(GetUserNotifications.pending, state => {
        state.userNotificationStatus = 'pending'
      }),
      builder.addCase(GetUserNotifications.rejected, (state, { error }) => {
        state.userNotificationStatus = 'failed'
        state.userNotificationError = error.message
      })

    builder.addCase(UpdateNotificationSettings.fulfilled, (state, action) => {
      const { action: actionId, channel: channelId, active } = action.meta.arg
      const channel = state.userNotificationSettingsData.find(c => c.id == channelId)
      const actionItem = channel.actions.find(ac => ac.id == actionId)
      actionItem.active = active
      state.userNotificationStatus = 'succeeded'
      state.userNotificationError = ''
    }),
      builder.addCase(UpdateNotificationSettings.pending, state => {
        state.userNotificationStatus = 'pending'
      }),
      builder.addCase(UpdateNotificationSettings.rejected, (state, { error }) => {
        state.userNotificationStatus = 'failed'
        state.userNotificationError = error.message
      })

    builder.addCase(MarkNotificationRead.fulfilled, state => {
      state.markNotificationStatus = 'succeeded'
      state.markNotificationError = ''
    }),
      builder.addCase(MarkNotificationRead.pending, state => {
        state.markNotificationStatus = 'pending'
      }),
      builder.addCase(MarkNotificationRead.rejected, (state, { error }) => {
        state.markNotificationStatus = 'failed'
        state.markNotificationError = error.message
      })
  },
})

export default Notifications.reducer
