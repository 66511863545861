import { ChangeEventHandler, Fragment, ReactElement, useEffect, useState, forwardRef } from 'react'
import { Eye, EyeSlash, Danger } from 'iconsax-react'

import S from './Input.module.scss'

interface InputProps {
  type: 'text' | 'email' | 'password' | 'url' | 'tel' | 'textarea' | 'number'
  name: string
  label?: string
  errors?: any
  value?: string | number
  aside?: string
  placeholder: string
  icon?: any
  disabled?: boolean
  register?: any
  max?: number
  validationSchema?: any
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const Input = ({
  type,
  label,
  name,
  icon,
  errors,
  max,
  register,
  validationSchema,
  placeholder,
  disabled,
  onChange,
  value,
  aside,
  ...props
}: InputProps, ref: any): ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(true)
  const [error, setError] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    errors && setError(errors[name]?.message)
  })
  return (
    <Fragment>
      {validationSchema ? (
        <Fragment>
          {type === 'password' ? (
            <div className={`${error ? S.input_error : ''} ${S.input} input`}>
              {label && <label>{label}</label>}
              <input
                ref={ref}
                {...register(name, validationSchema)}
                readOnly={disabled}
                name={name}
                type={showPassword ? 'password' : 'text'}
                placeholder={placeholder}
              />
              {!showPassword ? (
                <span onClick={() => setShowPassword(!showPassword)}>
                  <Eye size="20" color="#b1aec6" />
                </span>
              ) : (
                <span onClick={() => setShowPassword(!showPassword)}>
                  <EyeSlash size="20" color="#b1aec6" />
                </span>
              )}
              {error && <p>{error}</p>}
            </div>
          ) : type === 'textarea' ? (
            <>
              <div className={`${error ? S.input_error : ''} ${S.input} input`}>
                {label && <label>{label}</label>}
                <textarea ref={ref} readOnly={disabled} name={name} placeholder={placeholder} />
                {error && <p>{error}</p>}
                {error && (
                  <span>
                    <Danger size="20" color="#C55261" variant="Bold" />
                  </span>
                )}
              </div>
              {icon && <span>{icon}</span>}
            </>
          ) : (
            <>
              <div className={`${error ? S.input_error : ''} ${S.input} input`}>
                {label && <label>{label}</label>}
                <input
                  ref={ref}
                  readOnly={disabled}
                  {...register(name, validationSchema)}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                />
                {error && <p>{error}</p>}
                {error && (
                  <span>
                    <Danger size="20" color="#C55261" variant="Bold" />
                  </span>
                )}
              </div>
              {icon && <span>{icon}</span>}
            </>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {type === 'password' ? (
            <div className={`input ${S.input}`}>
              {label && <label>{label}</label>}
              <input
                ref={ref}
                readOnly={disabled}
                name={name}
                type={showPassword ? 'password' : 'text'}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
              />
              {!showPassword ? (
                <span onClick={() => setShowPassword(!showPassword)}>
                  <Eye size="20" color="#b1aec6" />
                </span>
              ) : (
                <span onClick={() => setShowPassword(!showPassword)}>
                  <EyeSlash size="20" color="#b1aec6" />
                </span>
              )}
            </div>
          ) : type === 'textarea' ? (
            <>
              <div className={`${error ? S.input_error : ''} ${S.input} input`}>
                {label && <label>{label}</label>}
                <textarea
                  ref={ref}
                  maxLength={max}
                  readOnly={disabled}
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                />
                {error && <p>{error}</p>}
                {error && (
                  <span>
                    <Danger size="20" color="#C55261" variant="Bold" />
                  </span>
                )}
                <aside>{aside}</aside>
              </div>
              {icon && <span>{icon}</span>}
            </>
          ) : (
            <>
              <div className={`${error ? S.input_error : ''} ${S.input} input`}>
                {label && <label>{label}</label>}
                <input
                  ref={ref}
                  {...props}
                  readOnly={disabled}
                  name={name}
                  type={type}
                  max={max}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                />
                {error && <p>{error}</p>}
                {error && (
                  <span>
                    <Danger size="20" color="#C55261" variant="Bold" />
                  </span>
                )}
              </div>
              {icon && <span>{icon}</span>}
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default forwardRef(Input)
