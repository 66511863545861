import { userTimezone } from '@/utils'
import { DateTime } from 'luxon'
import { format, isBefore, addMinutes, isFuture } from 'date-fns'
import { useMemo } from 'react'

function useTimeSlots(userScheduleData: any, selectedDate: Date, checkedTimeframe: number) {
  const availableRange = useMemo(() => {
    return getAvailabelTimeSlotsForDate(userScheduleData, selectedDate, checkedTimeframe)
  }, [userScheduleData, selectedDate, checkedTimeframe])

  return availableRange
}

export function getAvailabelTimeSlotsForDate(userScheduleData: any, date: Date, duration: number) {
  let range = userScheduleData?.date_overides?.find((override: any) => {
    return override.date_override === format(date, 'yyyy-MM-dd')
  })

  if (!range) {
    range = userScheduleData?.availabilities?.find((availability: any) => {
      return availability?.day?.toUpperCase() === format(date, 'EEEE').toUpperCase()
    })
  }

  if (!range) {
    return []
  }
  const timezone = range.timezone

  const makeTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(':')
    
    const inOriginalTime = DateTime.fromObject(
      {
        hour: +hours,
        minute: +minutes,
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        day: date.getDate(),
      },
      { zone: timezone }
    )

    const inMyTime = inOriginalTime.setZone(userTimezone)

    return inMyTime.toJSDate()
  }
  const formatTime = (time: Date) => format(time, 'HH:mm')

  return range.available_times?.reduce((acc: string[], tr: any) => {
    const { time_from, time_to } = tr

    let curr = makeTime(time_from)
    const end = makeTime(time_to)

    while (isBefore(curr, end)) {
      const isAfterNow = isFuture(curr)
      const value = formatTime(curr)
      if (isAfterNow) {
        acc.push(value)
      }
      curr = addMinutes(curr, duration)
    }
    return acc
  }, [])
}

export default useTimeSlots
