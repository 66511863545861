import { baseUrl, setCookie } from '@/utils/session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Router from 'next/router'
import { GetPaymentTypes } from '../payment'
import { GetEducation } from '../userData/education'
import { GetExperience } from '../userData/experience'
import { GetInterests } from '../userData/interests'
import { GetSocials } from '../userData/socials'
import { GetUserData } from '../userData/updateUser'
import { errorMessage } from '../utils/generalMessage'
import { authUser } from './userAuth'

interface ISocialAuth {
  socialCallbackStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  socialCallbackError: string
  socialCallbackData: any
}

const initialState: ISocialAuth = {
  socialCallbackStatus: 'idle',
  socialCallbackError: '',
  socialCallbackData: {},
}

export const socialCallback = createAsyncThunk(
  'auth/socialCallback',
  async (
    { code, state, provider }: { code: string; state: string; provider: 'linkedin' | 'google' },
    thunkAPI
  ) => {
    try {
      const response = await axios.get(
        `${baseUrl}/oauth/${provider}/callback?code=${code}&state=${state}`
      )
      let data = response.data

      if (response.status < 400) {
        setCookie('access_token', data.access_token)
        thunkAPI.dispatch(authUser())
        thunkAPI.dispatch(GetUserData())
        thunkAPI.dispatch(GetSocials())
        thunkAPI.dispatch(GetInterests())
        thunkAPI.dispatch(GetExperience())
        thunkAPI.dispatch(GetEducation())
        thunkAPI.dispatch(GetPaymentTypes())

        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))
        Router.push(queryParams.state ?? '/explore')

        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const socialAuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(socialCallback.fulfilled, (state, { payload }) => {
      state.socialCallbackStatus = 'succeeded'
      state.socialCallbackData = payload
    }),
      builder.addCase(socialCallback.pending, state => {
        state.socialCallbackStatus = 'pending'
      }),
      builder.addCase(socialCallback.rejected, (state, { error }) => {
        state.socialCallbackStatus = 'failed'
        state.socialCallbackError = error.message
      })
  },
})

export default socialAuthSlice.reducer
