import { createSlice } from '@reduxjs/toolkit'

export interface GeneralMessage {
  generalMessageState: 'idle' | 'succeeded' | 'failed'
  generalMessage: string
  timeout: number
}

const initialState: GeneralMessage = {
  generalMessageState: 'idle',
  generalMessage: '',
  timeout: 0,
}

export const generalMessageSlice = createSlice({
  name: 'generalMessage',
  initialState,
  reducers: {
    successMessage: (state, { payload }) => {
      state.generalMessageState = 'succeeded'
      state.generalMessage = typeof payload === 'string' ? payload : payload.message
      state.timeout = typeof payload === 'string' ? null : payload.timeout
    },
    errorMessage: (state, { payload }) => {
      state.generalMessageState = 'failed'
      state.generalMessage = typeof payload === 'string' ? payload : payload.message
      state.timeout = typeof payload === 'string' ? null : payload.timeout
    },
    clearMessage: state => {
      state.generalMessage = ''
      state.generalMessageState = 'idle'
    },
  },
})

export const { successMessage, errorMessage, clearMessage } = generalMessageSlice.actions

export default generalMessageSlice.reducer
