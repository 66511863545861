import { useOutsideAlerter } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { DeleteExperience } from '@/redux/slices/userData/experience'
import { Button } from '@/shared'
import { motion } from 'framer-motion'
import { useRef } from 'react'
import ClientOnlyPortal from '../ClientPortal'
import S from './Experience.module.scss'

const Delete = ({ handleModal, experience }: { handleModal: () => void; experience: any }) => {
  const modalRef = useRef(null)
  useOutsideAlerter(modalRef, handleModal)

  const dispatch = useAppDispatch()
  const { deleteExperienceStatus } = useAppSelector(({ experience }) => experience)

  const handleDelete = () => {
    dispatch(DeleteExperience(experience?.id))
    setTimeout(() => handleModal(), 2000)
  }

  return (
    <ClientOnlyPortal selector="#portal">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'tween', duration: 0.25 }}
        className={S.delete}>
        <div ref={modalRef} className={S.delete_main}>
          <svg
            width="101"
            height="100"
            viewBox="0 0 101 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="50.5" cy="50" r="50" fill="#FAFAFD" />
            <path
              d="M34 42H68C66.6053 59.4343 65.6968 67.142 65.2748 70.2105C65.1133 71.384 64.4926 72.4 63.3633 72.7575C61.5753 73.3236 57.9787 74 51 74C44.0213 74 40.4247 73.3236 38.6367 72.7575C37.5074 72.4 36.8867 71.384 36.7252 70.2105C36.3032 67.142 35.3947 59.4343 34 42Z"
              fill="#C7C0ED"
            />
            <path
              d="M48.883 34C47.1613 34 45.6328 35.1017 45.0883 36.7351L44.6332 38.1003C41.3721 38.2069 38.7966 38.3829 36.9068 38.5512C34.5275 38.763 32.626 40.499 32.0467 42.8164L31.779 43.8871C31.3243 45.7059 32.2951 47.3191 34.1619 47.4918C36.8925 47.7445 41.9029 48 51.0008 48C60.0986 48 65.109 47.7445 67.8397 47.4918C69.7065 47.3191 70.6773 45.7059 70.2226 43.8871L69.9549 42.8164C69.3755 40.499 67.4741 38.763 65.0947 38.5512C63.2047 38.3829 60.6287 38.2068 57.3667 38.1002L56.9117 36.7351C56.3672 35.1017 54.8387 34 53.117 34H48.883Z"
              fill="#5e48db"
            />
            <path
              d="M45.1435 53C43.9857 53 43.0711 53.9823 43.1536 55.137L43.8686 65.1472C43.9432 66.1912 44.8119 67 45.8585 67C47.0162 67 47.9308 66.0177 47.8483 64.863L47.1333 54.8528C47.0587 53.8088 46.1901 53 45.1435 53Z"
              fill="#5e48db"
            />
            <path
              d="M56.8585 53C55.8119 53 54.9432 53.8088 54.8686 54.8528L54.1536 64.8629C54.0711 66.0177 54.9857 67 56.1435 67C57.1901 67 58.0587 66.1912 58.1333 65.1472L58.8483 55.1371C58.9308 53.9823 58.0162 53 56.8585 53Z"
              fill="#5e48db"
            />
          </svg>
          <h3>Are you sure you want to delete this experience?</h3>
          <div className={S.delete_main_footer}>
            <Button usage="submit" type={'secondary'} onClick={handleModal}>
              Cancel
            </Button>
            <Button
              usage="submit"
              type={'primary'}
              loading={deleteExperienceStatus === 'pending'}
              onClick={handleDelete}>
              Continue
            </Button>
          </div>
        </div>
      </motion.section>
    </ClientOnlyPortal>
  )
}

export default Delete
