import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'

import { closeAddSchedule, closeOverrideModal } from '../utils/modalService'
import http from '@/utils/axiosInstance'
import Router from 'next/router'

interface ISchedule {
  createScheduleStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createScheduleError: string

  createScheduleAvailabilityStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createScheduleAvailabilityError: string

  createScheduleOverrideStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createScheduleOverrideError: string

  deleteScheduleOverrideError: string
  deleteScheduleOverrideStatus: 'idle' | 'pending' | 'succeeded' | 'failed'

  userScheduleData: any
  userScheduleStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userScheduleError: string

  scheduleData: any
  scheduleStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  scheduleError: string

  scheduleOverrideData: any
  scheduleOverrideStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  scheduleOverrideError: string

  scheduleAvailabilityData: any
  scheduleAvailabilityStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  scheduleAvailabilityError: string
}

interface ICreateSchedule {
  schedule_id: number
  role?: string
  userSlug?: string
  availableTimes: {
    date_override?: string
    timezone: string
    available_times?: {
      time_from: string
      time_to: string
    }[]
  }[]
}

const initialState: ISchedule = {
  createScheduleStatus: 'idle',
  createScheduleError: '',

  createScheduleAvailabilityStatus: 'idle',
  createScheduleAvailabilityError: '',

  createScheduleOverrideStatus: 'idle',
  createScheduleOverrideError: '',

  deleteScheduleOverrideStatus: 'idle',
  deleteScheduleOverrideError: '',

  userScheduleData: {},
  userScheduleStatus: 'idle',
  userScheduleError: '',

  scheduleData: [],
  scheduleStatus: 'idle',
  scheduleError: '',

  scheduleOverrideData: [],
  scheduleOverrideStatus: 'idle',
  scheduleOverrideError: '',

  scheduleAvailabilityData: [],
  scheduleAvailabilityStatus: 'idle',
  scheduleAvailabilityError: '',
}

export const CreateSchedule = createAsyncThunk(
  'schedule/createSchedule',
  async ({ name }: { name: string }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/pro/schedule`, { name })
      let data = response.data.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage('Schedule created successfully'))
        thunkAPI.dispatch(GetSchedule())
        thunkAPI.dispatch(closeAddSchedule())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CreateScheduleOverride = createAsyncThunk(
  'schedule/createScheduleOverride',
  async (formData: ICreateSchedule, thunkAPI) => {
    setAuthorizationHeader()

    try {
      const response = await http.post(`${baseUrl}/pro/date_override/${formData.schedule_id}`, {
        availabilities: formData.availableTimes,
      })
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage('Schedule updated successfully'))
        thunkAPI.dispatch(GetScheduleOverride(formData.schedule_id))
        thunkAPI.dispatch(closeOverrideModal())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteScheduleOverride = createAsyncThunk(
  'schedule/deleteScheduleOverride',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()

    try {
      const response = await http.delete(`${baseUrl}/pro/date_override/${formData.dateOverrideId}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Override deleted successfully'))
        thunkAPI.dispatch(GetScheduleOverride(formData.schedule_id))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CreateScheduleAvailability = createAsyncThunk(
  'schedule/createScheduleAvailability',
  async (formData: ICreateSchedule, thunkAPI) => {
    setAuthorizationHeader()

    try {
      const response = await http.post(`${baseUrl}/pro/availability/${formData.schedule_id}`, {
        availabilities: formData.availableTimes,
      })
      let data = response.data

      if (response.status < 400) {
        if (formData.role === 'client') {
          Router.push('/become-a-pro/duration')
        } else Router.push('/profile')

        // thunkAPI.dispatch(successMessage('Schedule updated successfully'))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetUserSchedule = createAsyncThunk(
  'schedule/getUserSchedule',
  async (userSlug: string) => {
    setAuthorizationHeader()
    return await http.get(`${baseUrl}/schedule/${userSlug}`).then(response => response.data.data)
  }
)

export const GetSchedule = createAsyncThunk('schedule/getSchedule', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/pro/schedule`).then(response => response.data.data)
})

export const GetScheduleAvailability = createAsyncThunk(
  'schedule/getScheduleAvailability',
  async (schedule_id: number) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/pro/availability/${schedule_id}`)
      .then(response => response.data.data)
  }
)

export const GetScheduleOverride = createAsyncThunk(
  'schedule/getScheduleOverride',
  async (schedule_id: number) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/pro/date_override/${schedule_id}`)
      .then(response => response.data.data)
  }
)

export const Schedule = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(CreateSchedule.fulfilled, state => {
      state.createScheduleStatus = 'succeeded'
      state.createScheduleError = ''
    }),
      builder.addCase(CreateSchedule.pending, state => {
        state.createScheduleStatus = 'pending'
      }),
      builder.addCase(CreateSchedule.rejected, (state, { error }) => {
        state.createScheduleStatus = 'failed'
        state.createScheduleError = error.message
      })

    builder.addCase(CreateScheduleAvailability.fulfilled, state => {
      state.createScheduleAvailabilityStatus = 'succeeded'
      state.createScheduleAvailabilityError = ''
    }),
      builder.addCase(CreateScheduleAvailability.pending, state => {
        state.createScheduleAvailabilityStatus = 'pending'
      }),
      builder.addCase(CreateScheduleAvailability.rejected, (state, { error }) => {
        state.createScheduleAvailabilityStatus = 'failed'
        state.createScheduleAvailabilityError = error.message
      })

    builder.addCase(CreateScheduleOverride.fulfilled, state => {
      state.createScheduleOverrideStatus = 'succeeded'
      state.createScheduleOverrideError = ''
    }),
      builder.addCase(CreateScheduleOverride.pending, state => {
        state.createScheduleOverrideStatus = 'pending'
      }),
      builder.addCase(CreateScheduleOverride.rejected, (state, { error }) => {
        state.createScheduleOverrideStatus = 'failed'
        state.createScheduleOverrideError = error.message
      })

    builder.addCase(GetUserSchedule.fulfilled, (state, { payload }) => {
      state.userScheduleStatus = 'succeeded'
      state.userScheduleError = ''
      state.userScheduleData = payload
    }),
      builder.addCase(GetUserSchedule.pending, state => {
        state.userScheduleStatus = 'pending'
      }),
      builder.addCase(GetUserSchedule.rejected, (state, { error }) => {
        state.userScheduleStatus = 'failed'
        state.userScheduleError = error.message
      })

    builder.addCase(GetSchedule.fulfilled, (state, { payload }) => {
      state.scheduleStatus = 'succeeded'
      state.scheduleError = ''
      state.scheduleData = payload
    }),
      builder.addCase(GetSchedule.pending, state => {
        state.scheduleStatus = 'pending'
      }),
      builder.addCase(GetSchedule.rejected, (state, { error }) => {
        state.scheduleStatus = 'failed'
        state.scheduleError = error.message
      })

    builder.addCase(GetScheduleAvailability.fulfilled, (state, { payload }) => {
      state.scheduleAvailabilityStatus = 'succeeded'
      state.scheduleAvailabilityError = ''
      state.scheduleAvailabilityData = payload
    }),
      builder.addCase(GetScheduleAvailability.pending, state => {
        state.scheduleAvailabilityStatus = 'pending'
      }),
      builder.addCase(GetScheduleAvailability.rejected, (state, { error }) => {
        state.scheduleAvailabilityStatus = 'failed'
        state.scheduleAvailabilityError = error.message
      })

    builder.addCase(GetScheduleOverride.fulfilled, (state, { payload }) => {
      state.scheduleOverrideStatus = 'succeeded'
      state.scheduleOverrideError = ''
      state.scheduleOverrideData = payload
    }),
      builder.addCase(GetScheduleOverride.pending, state => {
        state.scheduleOverrideStatus = 'pending'
      }),
      builder.addCase(GetScheduleOverride.rejected, (state, { error }) => {
        state.scheduleOverrideStatus = 'failed'
        state.scheduleOverrideError = error.message
      })
  },
})

export default Schedule.reducer
