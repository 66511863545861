import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'
import Router from 'next/router'
import { closeProfileInterests } from '../utils/modalService'
import http from '@/utils/axiosInstance'

interface IUser {
  addInterestsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  addInterestsError: string

  interestsData: []
  interestsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  interestsError: string
}

const initialState: IUser = {
  addInterestsStatus: 'idle',
  addInterestsError: '',

  interestsData: [],
  interestsStatus: 'idle',
  interestsError: '',
}

export const AddInterests = createAsyncThunk(
  'interests/addInterests',
  async (interests: any[], thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/interestin/add`, {
        interest_in: interests,
      })
      let data = response.data

      if (response.status < 400) {
        if (Router.pathname === '/profile') {
          thunkAPI.dispatch(successMessage(data.message))
          thunkAPI.dispatch(GetInterests())
          thunkAPI.dispatch(closeProfileInterests())
        }
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetInterests = createAsyncThunk('interests/getInterests', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/interestin/get`).then(response => response.data)
})

export const Interests = createSlice({
  name: 'interests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AddInterests.fulfilled, state => {
      state.addInterestsStatus = 'succeeded'
      state.addInterestsError = ''
    }),
      builder.addCase(AddInterests.pending, state => {
        state.addInterestsStatus = 'pending'
      }),
      builder.addCase(AddInterests.rejected, (state, { error }) => {
        state.addInterestsStatus = 'failed'
        state.addInterestsError = error.message
      })

    builder.addCase(GetInterests.fulfilled, (state, { payload }) => {
      state.interestsStatus = 'succeeded'
      state.interestsError = ''
      state.interestsData = payload.interestIn[0]?.interest_in
    }),
      builder.addCase(GetInterests.pending, state => {
        state.interestsStatus = 'pending'
      }),
      builder.addCase(GetInterests.rejected, (state, { error }) => {
        state.interestsStatus = 'failed'
        state.interestsError = error.message
      })
  },
})

export default Interests.reducer
