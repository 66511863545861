import { userTimezone } from '@/utils/index'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'

import { setMeetingData, updateStep } from '../utils/meetingData'
import Router from 'next/router'
import { openRescheduleModal } from '../utils/modalService'
import http from '@/utils/axiosInstance'

interface IMeetingRequest {
  createMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createMeetingRequestError: string

  acceptMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  acceptMeetingRequestError: string

  rescheduleMeetingStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  rescheduleMeetingError: string

  declineMeetingStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  declineMeetingError: string

  cancelMeetingStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  cancelMeetingError: string

  scheduleMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  scheduleMeetingRequestError: string

  meetingRequestData: any
  meetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  meetingRequestError: string

  scheduledMeetingRequestData: any
  scheduledMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  scheduledMeetingRequestError: string

  pendingUserMeetingRequestData: any
  pendingUserMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  pendingUserMeetingRequestError: string

  userMeetingHistoryData: any
  userMeetingHistoryStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userMeetingHistoryError: string

  upcomingUserMeetingRequestData: any
  upcomingUserMeetingRequestStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  upcomingUserMeetingRequestError: string
}

const initialState: IMeetingRequest = {
  createMeetingRequestStatus: 'idle',
  createMeetingRequestError: '',

  acceptMeetingRequestStatus: 'idle',
  acceptMeetingRequestError: '',

  rescheduleMeetingStatus: 'idle',
  rescheduleMeetingError: '',

  declineMeetingStatus: 'idle',
  declineMeetingError: '',

  cancelMeetingStatus: 'idle',
  cancelMeetingError: '',

  scheduleMeetingRequestStatus: 'idle',
  scheduleMeetingRequestError: '',

  meetingRequestData: {},
  meetingRequestStatus: 'idle',
  meetingRequestError: '',

  userMeetingHistoryData: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
  userMeetingHistoryStatus: 'idle',
  userMeetingHistoryError: '',

  scheduledMeetingRequestData: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
  scheduledMeetingRequestStatus: 'idle',
  scheduledMeetingRequestError: '',

  pendingUserMeetingRequestData: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
  pendingUserMeetingRequestStatus: 'idle',
  pendingUserMeetingRequestError: '',

  upcomingUserMeetingRequestData: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
    },
  },
  upcomingUserMeetingRequestStatus: 'idle',
  upcomingUserMeetingRequestError: '',
}

export const AcceptMeetingRequest = createAsyncThunk(
  'meetingRequest/acceptMeetingRequest',
  async (meeting_id: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/meeting/accept/${meeting_id}`)
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Meeting scheduled successfully'))
        Router.push('/schedule')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const RescheduleMeeting = createAsyncThunk(
  'meetingRequest/rescheduleMeeting',
  async (
    {
      meeting_datetime,
      reason,
      meeting_id,
    }: { meeting_datetime: any; reason: string; meeting_id: string },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/meeting/reschedule/${meeting_id}`, {
        meeting_datetime,
        reason,
      })
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(openRescheduleModal())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CancelMeeting = createAsyncThunk(
  'meetingRequest/cancelMeeting',
  async ({ reason, meeting_id }: { reason: string; meeting_id: string }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/meeting/cancel/${meeting_id}`, {
        reason,
      })
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Meeting cancelled'))
        Router.push('/schedule')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeclineMeeting = createAsyncThunk(
  'meetingRequest/declineMeeting',
  async ({ reason, meeting_id }: { reason: string; meeting_id: string }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/meeting/decline/${meeting_id}`, {
        reason,
      })
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Meeting declined'))
        Router.push('/schedule')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CreateMeetingRequest = createAsyncThunk(
  'meetingRequest/createMeetingRequest',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()

    try {
      
      const response = await http.post('/meeting/request', {...formData, timezone: userTimezone})
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(updateStep(2))
        thunkAPI.dispatch(setMeetingData(data))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const ScheduleMeetingRequest = createAsyncThunk(
  'meetingRequest/scheduleMeetingRequest',
  async (meetingRequestId: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/meeting/schedule/${meetingRequestId}`)
      let data = response.data.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Meeting scheduled successfully'))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetMeetingRequest = createAsyncThunk(
  'meetingRequest/getMeetingRequest',
  async (meetingRequestId: string) => {
    setAuthorizationHeader()
    return await http
      .get(`/meeting/request/${meetingRequestId}`)
      .then(response => response.data.data)
  }
)

export const GetScheduledMeetingRequest = createAsyncThunk(
  'meetingRequest/getScheduledMeetingRequest',
  async ({ page, period }: { page: number; period: string }) => {
    setAuthorizationHeader()
    return await http
      .get(
        `/meeting/request/?status=scheduled&per_page=10&page=${
          1 * page
        }&period=${period}&timezone=${encodeURIComponent(userTimezone)}`
      )
      .then(response => response.data)
  }
)

export const GetUserMeetingHistory = createAsyncThunk(
  'meetingRequest/getUserMeetingHistory',
  async (page: number) => {
    setAuthorizationHeader()
    return await http
      .get(`/meeting/request/?status=history&per_page=10&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const GetPendingUserMeetingRequests = createAsyncThunk(
  'meetingRequest/getPendingUserMeetingRequests',
  async (page: number) => {
    setAuthorizationHeader()
    return await http
      .get(`/meeting/request/?status=reserved&per_page=10&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const GetUpcomingUserMeetingRequests = createAsyncThunk(
  'meetingRequest/getUpcomingUserMeetingRequests',
  async (page: number) => {
    setAuthorizationHeader()
    return await http
      .get(`/meeting/request/?status=upcoming&per_page=10&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const MeetingRequest = createSlice({
  name: 'meetingRequest',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(CreateMeetingRequest.fulfilled, state => {
      state.createMeetingRequestStatus = 'succeeded'
      state.createMeetingRequestError = ''
    }),
      builder.addCase(CreateMeetingRequest.pending, state => {
        state.createMeetingRequestStatus = 'pending'
      }),
      builder.addCase(CreateMeetingRequest.rejected, (state, { error }) => {
        state.createMeetingRequestStatus = 'failed'
        state.createMeetingRequestError = error.message
      })

    builder.addCase(AcceptMeetingRequest.fulfilled, state => {
      state.acceptMeetingRequestStatus = 'succeeded'
      state.acceptMeetingRequestError = ''
    }),
      builder.addCase(AcceptMeetingRequest.pending, state => {
        state.acceptMeetingRequestStatus = 'pending'
      }),
      builder.addCase(AcceptMeetingRequest.rejected, (state, { error }) => {
        state.acceptMeetingRequestStatus = 'failed'
        state.acceptMeetingRequestError = error.message
      })

    builder.addCase(RescheduleMeeting.fulfilled, state => {
      state.rescheduleMeetingStatus = 'succeeded'
      state.rescheduleMeetingError = ''
    }),
      builder.addCase(RescheduleMeeting.pending, state => {
        state.rescheduleMeetingStatus = 'pending'
      }),
      builder.addCase(RescheduleMeeting.rejected, (state, { error }) => {
        state.rescheduleMeetingStatus = 'failed'
        state.rescheduleMeetingError = error.message
      })

    builder.addCase(CancelMeeting.fulfilled, state => {
      state.cancelMeetingStatus = 'succeeded'
      state.cancelMeetingError = ''
    }),
      builder.addCase(CancelMeeting.pending, state => {
        state.cancelMeetingStatus = 'pending'
      }),
      builder.addCase(CancelMeeting.rejected, (state, { error }) => {
        state.cancelMeetingStatus = 'failed'
        state.cancelMeetingError = error.message
      })

    builder.addCase(DeclineMeeting.fulfilled, state => {
      state.declineMeetingStatus = 'succeeded'
      state.declineMeetingError = ''
    }),
      builder.addCase(DeclineMeeting.pending, state => {
        state.declineMeetingStatus = 'pending'
      }),
      builder.addCase(DeclineMeeting.rejected, (state, { error }) => {
        state.declineMeetingStatus = 'failed'
        state.declineMeetingError = error.message
      })

    builder.addCase(ScheduleMeetingRequest.fulfilled, state => {
      state.scheduleMeetingRequestStatus = 'succeeded'
      state.scheduleMeetingRequestError = ''
    }),
      builder.addCase(ScheduleMeetingRequest.pending, state => {
        state.scheduleMeetingRequestStatus = 'pending'
      }),
      builder.addCase(ScheduleMeetingRequest.rejected, (state, { error }) => {
        state.scheduleMeetingRequestStatus = 'failed'
        state.scheduleMeetingRequestError = error.message
      })

    builder.addCase(GetMeetingRequest.fulfilled, (state, { payload }) => {
      state.meetingRequestStatus = 'succeeded'
      state.meetingRequestError = ''
      state.meetingRequestData = payload
    }),
      builder.addCase(GetMeetingRequest.pending, state => {
        state.meetingRequestStatus = 'pending'
      }),
      builder.addCase(GetMeetingRequest.rejected, (state, { error }) => {
        state.meetingRequestStatus = 'failed'
        state.meetingRequestError = error.message
      })

    builder.addCase(GetScheduledMeetingRequest.fulfilled, (state, { payload }) => {
      state.scheduledMeetingRequestStatus = 'succeeded'
      state.scheduledMeetingRequestError = ''
      state.scheduledMeetingRequestData = payload
    }),
      builder.addCase(GetScheduledMeetingRequest.pending, state => {
        state.scheduledMeetingRequestStatus = 'pending'
      }),
      builder.addCase(GetScheduledMeetingRequest.rejected, (state, { error }) => {
        state.scheduledMeetingRequestStatus = 'failed'
        state.scheduledMeetingRequestError = error.message
      })

    builder.addCase(GetPendingUserMeetingRequests.fulfilled, (state, { payload }) => {
      state.pendingUserMeetingRequestStatus = 'succeeded'
      state.pendingUserMeetingRequestError = ''
      state.pendingUserMeetingRequestData = payload
    }),
      builder.addCase(GetPendingUserMeetingRequests.pending, state => {
        state.pendingUserMeetingRequestStatus = 'pending'
      }),
      builder.addCase(GetPendingUserMeetingRequests.rejected, (state, { error }) => {
        state.pendingUserMeetingRequestStatus = 'failed'
        state.pendingUserMeetingRequestError = error.message
      })

    builder.addCase(GetUserMeetingHistory.fulfilled, (state, { payload }) => {
      state.userMeetingHistoryStatus = 'succeeded'
      state.userMeetingHistoryError = ''
      state.userMeetingHistoryData = payload
    }),
      builder.addCase(GetUserMeetingHistory.pending, state => {
        state.userMeetingHistoryStatus = 'pending'
      }),
      builder.addCase(GetUserMeetingHistory.rejected, (state, { error }) => {
        state.userMeetingHistoryStatus = 'failed'
        state.userMeetingHistoryError = error.message
      })

    builder.addCase(GetUpcomingUserMeetingRequests.fulfilled, (state, { payload }) => {
      state.upcomingUserMeetingRequestStatus = 'succeeded'
      state.upcomingUserMeetingRequestError = ''
      state.upcomingUserMeetingRequestData = payload
    }),
      builder.addCase(GetUpcomingUserMeetingRequests.pending, state => {
        state.upcomingUserMeetingRequestStatus = 'pending'
      }),
      builder.addCase(GetUpcomingUserMeetingRequests.rejected, (state, { error }) => {
        state.upcomingUserMeetingRequestStatus = 'failed'
        state.upcomingUserMeetingRequestError = error.message
      })
  },
})

export default MeetingRequest.reducer
