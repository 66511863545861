import http from '@/utils/axiosInstance'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../../utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'

interface IUserState {
  verifyEmail: any
  verifyEmailStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  verifyEmailError: string
}

const initialState: IUserState = {
  verifyEmail: {},
  verifyEmailStatus: 'idle',
  verifyEmailError: '',
}

export const verifyEmail = createAsyncThunk(
  'signup/verifyEmail',
  async (email: string, thunkAPI) => {
    try {
      const response = await http.post(`${baseUrl}/verify/resend`, { email })
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message || 'Request sent successfully'))
        return { ...data, email }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const verifySlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(verifyEmail.fulfilled, state => {
      state.verifyEmailStatus = 'succeeded'
    }),
      builder.addCase(verifyEmail.pending, state => {
        state.verifyEmailStatus = 'pending'
      }),
      builder.addCase(verifyEmail.rejected, (state, { error }) => {
        state.verifyEmailStatus = 'failed'
        state.verifyEmailError = error.message
      })
  },
})

export default verifySlice.reducer
