import http from '@/utils/axiosInstance'
import { setCookie } from '@/utils/session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from '../utils/generalMessage'

import Router from 'next/router'

import { GetPaymentTypes } from '../payment'
import { GetEducation } from '../userData/education'
import { GetExperience } from '../userData/experience'
import { GetInterests } from '../userData/interests'
import { GetSocials } from '../userData/socials'
import { GetUserData } from '../userData/updateUser'
import { authUser } from './userAuth'

interface IInviteeState {
  inviteeStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  inviteeError: string
}

const initialState: IInviteeState = {
  inviteeStatus: 'idle',
  inviteeError: '',
}

export const invitee = createAsyncThunk(
  'auth/invitee',
  async (
    { name, invite_token, password, meeting_request_id }: { name: string; invite_token: string; password: string, meeting_request_id: number },
    thunkAPI
  ) => {
    try {
      const response = await http.post('/auth/invitee', { name, invite_token, password, meeting_request_id })
      let data = response.data

      if (response.status < 400) {
        setCookie('access_token', data.access_token)
        Router.push('/schedule')
        thunkAPI.dispatch(authUser())
        thunkAPI.dispatch(GetUserData())
        thunkAPI.dispatch(GetSocials())
        thunkAPI.dispatch(GetInterests())
        thunkAPI.dispatch(GetExperience())
        thunkAPI.dispatch(GetEducation())
        thunkAPI.dispatch(GetPaymentTypes())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const inviteeSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(invitee.fulfilled, state => {
      state.inviteeStatus = 'succeeded'
    }),
      builder.addCase(invitee.pending, state => {
        state.inviteeStatus = 'pending'
      }),
      builder.addCase(invitee.rejected, (state, { error }) => {
        state.inviteeStatus = 'failed'
        state.inviteeError = error.message
      })
  },
})

export default inviteeSlice.reducer
