import React, { ReactElement } from 'react'
import S from './Button.module.scss'

export interface AppButtonProps {
  usage?: 'submit' | 'reset' | 'button'
  type: 'primary' | 'secondary' | 'disabled'
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  onClick?: () => void | null
  className?: string
}

const Button = ({
  type,
  usage,
  children,
  onClick,
  className = '',
  disabled,
  loading,
}: AppButtonProps): ReactElement => {
  return (
    <button
      type={usage}
      disabled={disabled || loading}
      onClick={onClick}
      className={`${S.button} ${className}`}
      data-type={type}>
      {loading ? (
        <>
          {type === 'primary' ? <span className={S.loading} /> : <span className={S.loading_sec} />}
        </>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
