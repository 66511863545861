import Image from 'next/image'
import S from './ProLarge.module.scss'
import { Button } from '..'
import { useRouter } from 'next/router'

const Pro = ({ onClick }: { onClick: () => void }) => {
  const router = useRouter()

  return (
    <div className={S.pro}>
      <Image src="/badge.svg" width="92" height="120" alt="badge" />
      <div className={S.pro_div}>
        <h4>Become a PRO!</h4>
        <p>
          By becoming a PRO, you are able to monetize your time, skills & knowledge. People pay to
          talk to you & learn from you.
        </p>
        <div className={S.pro_btn}>
          <Button
            type={'primary'}
            className={S.pro_button}
            onClick={() => router.push('/become-a-pro')}>
            Get started
          </Button>
          <Button type={'secondary'} className={S.pro_button} onClick={onClick}>
            No, thanks
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Pro
