import { DateTime } from 'luxon'

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000)
}
export const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const computeMeetingDateTime = (slot: string, dateValue: Date) => {
  if (!dateValue || !slot) {
    return ''
  }
  const [hour, minute] = slot.split(':')
  return DateTime.fromJSDate(dateValue)
    .set({ hour: +hour, minute: +minute })
    .setZone(userTimezone)
    .toJSDate()
    .toISOString()
}

export const ObjectHasData = (obj: Object | null) => Object.values(obj || {}).length > 0

export const formatMinutes = totalmins => {
  const absTotal = Math.abs(totalmins)
  const mins = absTotal % 60
  const hours = Math.floor(absTotal / 60)
  const days = Math.floor(hours / 24)
  const hourss = hours % 24
  if (days === 1) return `${days} day ${hourss} hrs ${mins} mins`
  else if (days > 1) return `${days} days ${hourss} hrs ${mins} mins`
  else if (hourss) return `${hourss} hrs ${mins} mins`
  else return `${mins} mins`
}

export const meetingTypes = {
  VIDEO: 'Video Call',
  AUDIO: 'Voice Call',
  CHAT: 'Chat',
}

export const timeframes = [15, 20, 30, 45, 60]

export const meetingTimes: { label: string; value: number }[] = [
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '20 minutes',
    value: 20,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '45 minutes',
    value: 45,
  },
  {
    label: '1 hour',
    value: 60,
  },
]

export const numberToCurrency = (number: number, currency?: string) => {
  return (number / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: currency ? currency : 'USD',
  })
}

export const numberToCurrencyNoDecimals = (
  number: number,
  decimals?: number,
  currency?: string
) => {
  return (number / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: currency ? currency : 'USD',
    maximumFractionDigits: decimals || 0,
  })
}

export const formatNumber = (number: number) => {
  return number / 100
}

export const currencyToNumber = (number: number) => {
  return number * 100
}

export const minToHr = (min: number) => {
  return min / 60
}

export const hrToMin = (min: number) => {
  return min * 60
}

export const ceil = Math.ceil

export const isDevelopment = process.env.NODE_ENV === 'production' ? false : true

export const defaultTime = { time_from: '08:00', time_to: '17:00' }

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const bcColor = '#6D6C75'

export const pfpSize = 5000000

export const buildYears = (total = 50, upperBound = 10) =>
  Array(total)
    .fill(0)
    .map((_, index) => {
      return new Date().getFullYear() - index + upperBound
    })

export const buildOptions = (values: any[] = [], labelKey?: string, valueKey?: string) => {
  return values.map(v => ({
    label: labelKey ? v[labelKey]?.toString() : v.toString(),
    value: valueKey ? v[valueKey]?.toString() : v.toString(),
  }))
}
