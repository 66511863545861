import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { errorMessage } from '../utils/generalMessage'

interface INotes {
  notesData: any
  notesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  notesError: string

  createNoteStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createNoteError: string
}

const initialState: INotes = {
  notesStatus: 'idle',
  notesError: '',
  notesData: {
    content: '',
  },

  createNoteStatus: 'idle',
  createNoteError: '',
}

export const GetNotes = createAsyncThunk('notes/getNotes', async (meetingRequestId: string) => {
  setAuthorizationHeader()
  return await http
    .get(`${baseUrl}/meeting/${meetingRequestId}/notes`)
    .then(response => response.data)
})

export const CreateNote = createAsyncThunk(
  'notes/createNote',
  async (
    { content, meetingRequestId }: { content: string; meetingRequestId: string },
    thunkAPI
  ) => {
    setAuthorizationHeader()

    try {
      const response = await http.post(`${baseUrl}/meeting/${meetingRequestId}/notes`, { content })
      let data = response.data

      if (response.status < 400 || response.status < 400) {
        // thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Notes = createSlice({
  name: 'Notes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetNotes.fulfilled, (state, { payload }) => {
      state.notesStatus = 'succeeded'
      state.notesError = ''
      state.notesData = payload
    }),
      builder.addCase(GetNotes.pending, state => {
        state.notesStatus = 'pending'
      }),
      builder.addCase(GetNotes.rejected, (state, { error }) => {
        state.notesStatus = 'failed'
        state.notesError = error.message
      })

    builder.addCase(CreateNote.fulfilled, state => {
      state.createNoteStatus = 'succeeded'
      state.createNoteError = ''
    }),
      builder.addCase(CreateNote.pending, state => {
        state.createNoteStatus = 'pending'
      }),
      builder.addCase(CreateNote.rejected, (state, { error }) => {
        state.createNoteStatus = 'failed'
        state.createNoteError = error.message
      })
  },
})

export default Notes.reducer
