import NextLink from 'next/link'

export function Link({ href, children, ...rest }) {
  if (typeof href === 'string' && href.startsWith('http')) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }

  return (
    <NextLink href={href} scroll={false} {...rest}>
      {children}
    </NextLink>
  )
}
