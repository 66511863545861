import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

import {
  GetAllSkills,
  GetUserSkills,
  CreateSkill,
  UpdateSkill,
} from '@/redux/slices/BecomeAPro/skills'
import { ISelect } from '@/utils/mock'
import { closeProfileSkills } from '@/redux/slices/utils/modalService'

function useSkills(page: number = 1) {
  const dispatch = useAppDispatch()
  const [userSkills, setUserSkills] = useState<ISelect[]>([])
  const { skillsData, userSkillsData, createSkillsStatus } = useAppSelector(({ skills }) => skills)
  const skillArray: { label: string; value: string }[] = useMemo(() => {
    if (skillsData.data) {
      const arr = []
      skillsData.data?.map(skill =>
        arr.push({
          label: skill.name,
          value: skill.id.toString(),
        })
      )

      return arr
    }
  }, [skillsData.data])

  // GET ALL SKILLS
  const ref = useRef(false)
  useEffect(() => {
    if (!ref.current) {
      dispatch(
        GetAllSkills({
          keyword: '',
          page,
        })
      )
      dispatch(GetUserSkills())
    }
    return () => {
      ref.current = true
    }
  }, [dispatch, page])

  // USER SKILLS
  useEffect(() => {
    const arr = []
    userSkillsData.data?.map(skill => {
      arr.push({ label: skill.name, value: skill.id })
    })
    setUserSkills(arr)
  }, [userSkillsData])

  // CREATE SKILL IF NOT AVAILABLE
  const createSkills = (value: string) => {
    const skills = []
    userSkills.map(skill => skills.push(skill.value))
    dispatch(CreateSkill({ name: value, skills }))
  }

  const updateSkills = async () => {
    const skillArr = []
    userSkills.map(skill => skillArr.push(skill.value))
    await dispatch(UpdateSkill(skillArr))
    dispatch(closeProfileSkills())
  }

  return { skillArray, userSkills, createSkills, setUserSkills, updateSkills, isCreatingSkill: createSkillsStatus === 'pending' }
}

export default useSkills
