import { useRef, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

import { GetUserData } from '@/redux/slices/userData/updateUser'
import { GetSocials } from '@/redux/slices/userData/socials'
import { GetExperience } from '@/redux/slices/userData/experience'
import { GetEducation } from '@/redux/slices/userData/education'
import { GetPaymentTypes } from '@/redux/slices/payment'
import { GetRateData } from '@/redux/slices/BecomeAPro/profile'
import { GetCountries, GetCurrencies } from '@/redux/slices/utils/miscellaneous'
import { GetUserSchedule } from '@/redux/slices/interaction/schedule'
import { GetUserWithdrawalMethods } from '@/redux/slices/settings/withdrawals'
import { ObjectHasData } from '@/utils'
import { getCookie } from '@/utils/session'
import { GetUserNotifications } from '@/redux/slices/settings/notification'
import { GetMyCredentials } from '@/redux/slices/userData/credentials'

const useGeneralCalls = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const ref = useRef<boolean>(false)
  const ref1 = useRef<boolean>(false)
  const { userData, userDataStatus } = useAppSelector(({ updateUser }) => updateUser)

  const checkRoute = useMemo(() => {
    if (
      ![
        '/',
        '/signup',
        '/signup-form',
        '/signin',
        '/verify',
        '/reset',
        '/forgot-password',
        '/invitee',
      ].includes(router.pathname) &&
      !router.pathname.includes('/oauth')
    ) {
      return true
    }
    return false
  }, [router.pathname])

  useEffect(() => {
    if (!ref.current) {
      if (checkRoute) {
        dispatch(GetUserData())
        dispatch(GetSocials())
        dispatch(GetExperience())
        dispatch(GetEducation())
        dispatch(GetMyCredentials())
        dispatch(GetPaymentTypes())
        dispatch(GetUserWithdrawalMethods(1))
        dispatch(GetCountries())
        dispatch(GetCurrencies())
        dispatch(
          GetUserNotifications({
            status: null,
            page: 1,
          })
        )
      }
    }

    return () => {
      ref.current = true
    }
  }, [dispatch, checkRoute])

  useEffect(() => {
    if (!ref1.current) {
      if (checkRoute) {
        if (ObjectHasData(userData) && userData.role === 'pro') {
          dispatch(GetRateData(userData.profile?.slug))
          dispatch(GetUserSchedule(userData.profile?.slug))
          ref1.current = true
        }
      }
    }
  }, [checkRoute, dispatch, userData])

  // Check User
  const userRef = useRef(false)
  useEffect(() => {
    const token = getCookie('access_token', { req: {} })
    if (token && token !== 'undefined') {
      if (Object.keys(userData).length > 0 && !userRef.current && !router.query?.redirect) {
        if (userDataStatus !== 'pending' && !userData.profile?.fullname) {
          router.replace('/signup-form')
        }
        userRef.current = true
      }
    }
  }, [router, userData, userDataStatus])

  return null
}

export default useGeneralCalls
