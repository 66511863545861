import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { ArrowLeft, CloseCircle, Trash } from 'iconsax-react'

import ClientOnlyPortal from '../ClientPortal'
import S from '../Experience/Experience.module.scss'

import { Button, Select, Input } from '@/shared'

import { degrees, ISelect, months, years } from '@/utils/mock'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { UpdateEducation, AddEducation } from '@/redux/slices/userData/education'
import { useMediaQuery } from '@/hooks'
import { buildOptions, buildYears } from '@/utils'

const Add = ({
  handleModal,
  newAddition,
  education,
  handleDelete,
}: {
  handleDelete?: () => void
  handleModal: () => void
  newAddition?: boolean
  education?: any
}) => {
  const dispatch = useAppDispatch()
  const isTablet = useMediaQuery('(max-width: 768px)')
  const { addEducationStatus, updateEducationStatus } = useAppSelector(({ education }) => education)

  const [disabled, setDisabled] = useState<boolean>(true)
  const [school, setSchool] = useState<string>('')
  const [degree, setDegree] = useState<ISelect | null>(null)

  const [startMonth, setStartMonth] = useState<ISelect | null>(null)
  const [endMonth, setEndMonth] = useState<ISelect | null>(null)
  const [startYear, setStartYear] = useState<ISelect | null>(null)
  const [endYear, setEndYear] = useState<ISelect | null>(null)

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  const startYearsArray = useMemo(() => {
    return buildOptions(buildYears(30, 0))
  }, [])

  const endYearArray = useMemo(() => {
    const years = buildYears(30, 5)
    if (!startYear) {
      return years
    }
    return years.filter(it => it >= +startYear.value)
  }, [startYear])

  const startMonthsArray = useMemo(() => {
    return months
  }, [])

  const endMonthArray = useMemo(() => {
    if (!startYear || !endYear || !startMonth) {
      return months
    }
    return months.filter(month => {
      // if the education ends this year then
      // it cannot end in a month before the start month
      if (+endYear.value == +startYear.value) {
        return +month.value >= +startMonth.value + 1
      }
      //for any other year, it could be any month
      return months
    })
  }, [currentMonth, currentYear, endYear, startMonth, startYear])

  useEffect(() => {
    if (school && degree && startYear && endYear && startMonth && endMonth) {
      setDisabled(false)
    } else setDisabled(true)
  }, [degree, endMonth, endYear, school, startMonth, startYear])

  const handleClick = e => {
    e.preventDefault()
    if (!newAddition) {
      dispatch(
        UpdateEducation({
          id: education.id,
          school,
          degree: degree.value,
          grade: 'A',
          start_date: `${startMonth.value}/${startYear.value}`,
          end_date: `${endMonth.value}/${endYear.value}`,
        })
      )
    } else {
      dispatch(
        AddEducation({
          school,
          degree: degree.value,
          grade: 'A',
          start_date: `${startMonth.value}/${startYear.value}`,
          end_date: `${endMonth.value}/${endYear.value}`,
        })
      )
    }

    setTimeout(() => handleModal(), 2000)
  }

  useEffect(() => {
    if (education && Object.keys(education).length > 0) {
      setSchool(education.school)
      setDegree(degrees.filter(degree => degree.value === education?.degree)[0])
      setEndMonth(months.filter(month => month.value === education?.end_date?.split('/')[0])[0])
      setStartMonth(months.filter(month => month.value === education?.start_date?.split('/')[0])[0])
      setStartYear(years.filter(year => year.value === education?.start_date?.split('/')[1])[0])
      setEndYear(years.filter(year => year.value === education?.end_date?.split('/')[1])[0])
    }
  }, [education])

  return (
    <ClientOnlyPortal selector="#portal">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'tween', duration: 0.25 }}
        className={S.add}>
        <div className={S.add_main}>
          {isTablet ? (
            <div className="bcModal">
              <ArrowLeft size="24" color="#0a0a0a" onClick={handleModal} />
              <h3>Education</h3>
            </div>
          ) : (
            <header>
              <div>
                <h2>{newAddition ? 'Add education' : 'Update Education'}</h2>
                <p>Add an education to your profile</p>
              </div>
              <span role="button" onClick={handleModal}>
                <CloseCircle color="#0a0a0a" variant="Outline" />
              </span>
            </header>
          )}

          <form onSubmit={handleClick}>
            {isTablet && !newAddition && (
              <span className={S.icon} role="button" onClick={handleDelete}>
                <Trash size="20" color="#c55261" />
              </span>
            )}
            <Input
              onChange={e => setSchool(e.target.value)}
              value={school}
              label="School"
              type="text"
              name="school"
              placeholder="School"
            />

            <Select
              value={degree}
              setValue={setDegree}
              label="Degree"
              name="degree"
              placeholder="Choose degree"
              options={degrees}
            />

            <div className={S.flex}>
              <Select
                value={startYear}
                setValue={setStartYear}
                label="Start Date"
                name="startYear"
                placeholder="Year"
                options={startYearsArray}
              />

              <Select
                value={startMonth}
                setValue={setStartMonth}
                label="Month"
                name="startMonth"
                placeholder="Month"
                options={startMonthsArray}
              />
            </div>

            <div className={S.flex}>
              <Select
                value={endYear}
                setValue={setEndYear}
                label="End Date"
                name="endYear"
                placeholder="Year"
                options={buildOptions(endYearArray)}
                disabled={!startYear}
              />
              <Select
                value={endMonth}
                setValue={setEndMonth}
                label="Month"
                name="endMonth"
                placeholder="Month"
                options={endMonthArray}
                disabled={!endYear || !startYear || !startMonth}
              />
            </div>

            {/* <Select
							value={value}
							setValue={setValue}
							label='Grade'
							name='grade'
							placeholder='Choose grade'
							options={options}
						/> */}

            <Button
              type={disabled ? 'disabled' : 'primary'}
              disabled={disabled}
              loading={addEducationStatus === 'pending' || updateEducationStatus === 'pending'}
              usage="submit">
              {!newAddition ? 'Save' : 'Add education'}
            </Button>
          </form>
        </div>
      </motion.section>
    </ClientOnlyPortal>
  )
}

export default Add
