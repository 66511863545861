import http from '@/utils/axiosInstance'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from '../utils/generalMessage'

interface ISearchState {
  searchData: any
  searchStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  searchError: string
}

const initialState: ISearchState = {
  searchData: [],
  searchStatus: 'idle',
  searchError: '',
}

export const searchUsers = createAsyncThunk(
  'utils/searchUsers',
  async ({ keyword, field }: { keyword: string; field: string }, thunkAPI) => {
    try {
      const response = await http.get(`/search_profile?field=${field}&keyword=${keyword}`)
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        // thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const searchUserSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(searchUsers.fulfilled, (state, { payload }) => {
      state.searchStatus = 'succeeded'
      state.searchData = payload
    }),
      builder.addCase(searchUsers.pending, state => {
        state.searchStatus = 'pending'
      }),
      builder.addCase(searchUsers.rejected, (state, { error }) => {
        state.searchStatus = 'failed'
        state.searchError = error.message
      })
  },
})

export default searchUserSlice.reducer
