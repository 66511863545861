import { TickSquare } from 'iconsax-react'
import S from './Check.module.scss'

const Check = ({
  checked,
  onChange,
  onClick,
  name,
  label,
}: {
  checked: any
  onChange: any
  onClick?: any
  name: string
  label?: string
}) => {
  return (
    <label className={S.check}>
      <input name={name} onClick={onClick} type="checkbox" value={checked} onChange={onChange} />
      {!checked ? (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.0753 6.5066V6.50866V13.492C18.0753 14.8595 17.6706 15.8611 17.0117 16.52C16.3527 17.1789 15.3512 17.5837 13.9837 17.5837H7.00866C5.64116 17.5837 4.63983 17.179 3.98099 16.5193C3.32204 15.8595 2.91699 14.8559 2.91699 13.4837V6.50866C2.91699 5.14114 3.32172 4.13959 3.98066 3.48066C4.63959 2.82172 5.64114 2.41699 7.00866 2.41699H13.992C15.3596 2.41699 16.3609 2.82178 17.0186 3.48032C17.6762 4.13871 18.0791 5.13956 18.0753 6.5066Z"
            stroke="#b1aec6"
            strokeWidth="1.5"
          />
        </svg>
      ) : (
        <TickSquare size="24" color="#5e48db" variant="Bold" />
      )}
      {label && <p>{label}</p>}
    </label>
  )
}

export default Check
