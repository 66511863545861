import { useAppSelector } from '@/redux/hooks'
import { useIndustries, useSkills } from '.'

const useData = () => {
  const { userSkills } = useSkills()
  const { userIndustries } = useIndustries()
  const { rateData } = useAppSelector(({ profile }) => profile)
  const { socialsData } = useAppSelector(({ socials }) => socials)
  const { userData } = useAppSelector(({ updateUser }) => updateUser)
  const { experienceData } = useAppSelector(({ experience }) => experience)
  const { educationData } = useAppSelector(({ education }) => education)
  const { getMyCredentialsData } = useAppSelector(({ credentials }) => credentials)
  const { userScheduleData } = useAppSelector(({ schedule }) => schedule)
  const { banksData } = useAppSelector(({ withdrawals }) => withdrawals)
  const { countriesData, currencyData } = useAppSelector(({ miscellaneous }) => miscellaneous)

  return {
    countriesData,
    userSkills,
    userIndustries,
    socialsData,
    userData,
    experienceData,
    credentialsData: getMyCredentialsData,
    educationData,
    rateData,
    userScheduleData,
    currencyData,
    banksData,
  }
}

export default useData
