/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from 'react'

import S from './Payment.module.scss'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { AnimatePresence } from 'framer-motion'

import { useFlutterwave, closePaymentModal, FlutterWaveTypes } from 'flutterwave-react-v3'
import { Button } from '..'
import { formatNumber } from '@/utils'
import http from '@/utils/axiosInstance'
import { errorMessage } from '@/redux/slices/utils/generalMessage'
import { setAuthorizationHeader } from '@/utils/session'
import { GetWallet } from '@/redux/slices/interaction/wallet'
import { useExchangeRates } from '@/hooks/useExchangeRates'

const PaymentCard = ({
  amount,
  item_id,
  payment_type,
  description,
  onSuccess,
  onFailure,
}: {
  item_id: string
  amount: number
  payment_type: string
  description: string
  onSuccess: () => void
  // eslint-disable-next-line no-unused-vars
  onFailure: (message: string) => void
}) => {
  const dispatch = useAppDispatch()
  const [type, setType] = useState<'paypal' | 'stripe' | 'flutterwave' | 'wallet'>(null)
  const [currency, setCurrency] = useState<'USD' | 'NGN'>('USD')
  const [transactionData, setTransactionData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const vRef = useRef(false)

  const { userData } = useAppSelector(({ updateUser }) => updateUser)
  const { walletData } = useAppSelector(({ wallet }) => wallet)
  const { exchageRatesData, isReady: isExchangeRatesReady } = useExchangeRates()

  useEffect(() => {
    dispatch(GetWallet())
  }, [dispatch])

  const [showPopup, setShowPopup] = useState(false)

  const amountToPay = useMemo(() => {
    const rate = exchageRatesData[currency] ?? 1
    return Number(amount) * Number(rate)
  }, [amount, currency, exchageRatesData])

  const config: FlutterWaveTypes.FlutterwaveConfig = useMemo(() => {
    return {
      public_key: process.env.NEXT_PUBLIC_FLUTTERWAVE,
      tx_ref: transactionData.reference,
      amount: formatNumber(amountToPay),
      currency,
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email: userData?.email,
        name: userData?.profile?.fullname,
        phone_number: '',
      },
      customizations: {
        title: 'Pickt',
        description,
        logo: `${window.location.origin}/icon.png`,
      },
    }
  }, [
    amountToPay,
    currency,
    description,
    transactionData.reference,
    userData?.email,
    userData?.profile?.fullname,
  ])

  const InitializeTransaction = async payment_gateway => {
    setAuthorizationHeader()
    setLoading(true)
    try {
      const response = await http.post('/transaction/initialize', {
        payment_type,
        payment_gateway,
        item_id,
        amount: amountToPay,
        email: userData.email,
        currency,
      })

      let data = response.data
      setLoading(false)

      if (response.status < 400) {
        setTransactionData(data)
        return data
      } else {
        dispatch(errorMessage(data.message))
      }
    } catch (e) {
      setLoading(false)
      throw e
    }
  }

  const VerifyTransaction = async ({ reference, provider_reference }) => {
    setAuthorizationHeader()
    try {
      const response = await http.post('/transaction/verify', {
        reference,
        provider_reference,
        amount: amountToPay,
      })
      let data = response.data.data
      if (response.status < 400) {
        onSuccess()
      } else {
        onFailure(data.message)
      }
    } catch (e) {
      onFailure(e.response?.data?.message)
    }
  }

  return (
    <div className={S.payment_card}>
      <header>
        <h3>💳 Payment Method</h3>
      </header>

      <div className={S.payment_card_main}>
        <div className={S.payment_card_item}>
          <div
            className={`${type === 'flutterwave' ? S.active : ''} ${S.payment_card_item_head}`}
            role="button"
            onClick={() => {
              setType('flutterwave')
              setCurrency('NGN')
            }}>
            {type === 'flutterwave' ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#5e48db"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="12" cy="12" r="6" fill="#5e48db" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#b1aec6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            Flutterwave (NGN)
          </div>
          {type === 'flutterwave' && (
            <>
              <Button
                className={S.btn}
                type="primary"
                loading={loading}
                disabled={!isExchangeRatesReady}
                onClick={async () => {
                  await InitializeTransaction('FLUTTERWAVE')
                  setShowPopup(true)
                }}>
                Pay with Flutterwave
              </Button>
              {showPopup ? (
                <FlutterWavePopUp
                  config={config}
                  callback={async function (
                    data: FlutterWaveTypes.FlutterWaveResponse
                  ): Promise<void> {
                    if (!vRef.current) {
                      await VerifyTransaction({
                        reference: transactionData?.reference,
                        provider_reference: data.transaction_id.toString(),
                      })
                      vRef.current = true
                    }

                    setShowPopup(false)
                  }}
                  onClose={() => setShowPopup(false)}
                />
              ) : null}
            </>
          )}
        </div>

        {/* <div className={S.payment_card_item}>
          <div
            className={`${type === 'paypal' ? S.active : ''} ${S.payment_card_item_head}`}
            role="button"
            onClick={() => setType('paypal')}>
            {type === 'paypal' ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#5e48db"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="12" cy="12" r="6" fill="#5e48db" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#b1aec6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            PayPal
          </div>
          <AnimatePresence>{type === 'paypal' && <PayPal />}</AnimatePresence>
        </div> */}

        <div className={S.payment_card_item}>
          <div
            className={`${type === 'stripe' ? S.active : ''} ${S.payment_card_item_head}`}
            role="button"
            onClick={() => setType('stripe')}>
            {type === 'stripe' ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#5e48db"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="12" cy="12" r="6" fill="#5e48db" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#b1aec6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            Pay with Stripe (USD)
          </div>
          <AnimatePresence>
            {type === 'stripe' && (
              <Button
                className={S.btnWallet}
                type={'primary'}
                loading={loading}
                onClick={async () => {
                  try {
                    const data = await InitializeTransaction('STRIPE')
                    await VerifyTransaction({
                      reference: data?.reference,
                      provider_reference: `${payment_type.toUpperCase()}:${item_id}`,
                    })
                    console.log(data)
                  } catch (error) {
                    dispatch(errorMessage(error.response.data.message))
                  }
                }}>
                Pay with Stripe
              </Button>
            )}
          </AnimatePresence>
          {/* <AnimatePresence>{type === 'stripe' && <Card />}</AnimatePresence> */}
        </div>

        {payment_type !== 'TOPUP' && (
          <div className={S.payment_card_item}>
            <div
              className={`${type === 'wallet' ? S.active : ''} ${S.payment_card_item_head}`}
              role="button"
              onClick={() => setType('wallet')}>
              {type === 'wallet' ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#5e48db"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle cx="12" cy="12" r="6" fill="#5e48db" />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#b1aec6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              Wallet
            </div>
            <AnimatePresence>
              {type === 'wallet' && (
                <Button
                  className={S.btnWallet}
                  type={amount > walletData.available_balance ? 'disabled' : 'primary'}
                  disabled={amount > walletData.available_balance}
                  loading={loading}
                  onClick={async () => {
                    try {
                      const data = await InitializeTransaction('WALLET')
                      await VerifyTransaction({
                        reference: data?.reference,
                        provider_reference: `${payment_type.toUpperCase()}:${item_id}`,
                      })
                    } catch (error) {
                      dispatch(errorMessage(error.response.data.message))
                    }
                  }}>
                  Pay from Wallet
                </Button>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentCard

type flutterwaveProps = {
  config: FlutterWaveTypes.FlutterwaveConfig
  callback: FlutterWaveTypes.FlutterWaveProps['callback']
  onClose: FlutterWaveTypes.FlutterWaveProps['onclose']
}

const FlutterWavePopUp = ({ config, callback, onClose }: flutterwaveProps) => {
  const handleFlutterPayment = useFlutterwave(config)
  useEffect(() => {
    handleFlutterPayment({
      callback,
      onClose,
    })

    return () => {
      closePaymentModal()
    }
  }, [callback, handleFlutterPayment, onClose])

  return null
}
