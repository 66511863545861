import { forwardRef, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowDown2, CloseCircle } from 'iconsax-react'

import { AnimatePresence, motion } from 'framer-motion'

import { useOutsideAlerter } from '@/hooks'
import S from './Select.module.scss'

interface SelectProps {
  name: string
  label?: string
  value?: { label: string; value: string } | null
  values?: { label: string; value: string }[]
  setValue: any
  callback?: any
  multi?: boolean
  disabled?: boolean
  placeholder: string
  options: { label: string; value: string }[]
  isLoading?: boolean
}

const Select = (
  {
    label,
    value,
    values = [],
    setValue,
    callback,
    placeholder,
    multi = false,
    name,
    disabled,
    options = [],
    isLoading,
  }: SelectProps,
  ref: any
): ReactElement => {
  const [show, setShow] = useState(false)
  const handleShow = () => {
    !disabled && setShow(!show)
  }

  const selectRef = useRef(null)
  useOutsideAlerter(selectRef, () => setShow(false))

  const [newValues, setNewValues] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    if (multi) {
      setNewValues(values)
    }
  }, [multi, values])

  const addItem = (arr: { label: string; value: any }[], item: { label: string; value: any }) => {
    var index = arr.findIndex(x => x.value == item.value)
    if (index === -1) {
      arr.push(item)
    } else {
      console.log('object already exists')
    }
  }

  const handleClick = (item: { label: string; value: any }) => {
    if (multi) {
      const arr: { label: string; value: string }[] = [...newValues]
      addItem(arr, item)
      setNewValues([...arr])
      setValue([...arr])
      setInputValue('')
    } else {
      setValue(item)
      handleShow()
    }
  }

  const handleFilter = (option: { label: string; value: any }) => {
    const arr: { label: string; value: string }[] = [...newValues]
    const filteredItems = arr.filter(item => item.value !== option.value)
    setNewValues(filteredItems)
    setValue(filteredItems)
  }

  const [inputValue, setInputValue] = useState('')

  const newOptions: { label: string; value: string }[] = useMemo(() => {
    return options
      .filter(el => values.every(f => Number(f.value) !== Number(el.value)))
      .filter(option => option.label?.toLowerCase?.()?.includes?.(inputValue.toLowerCase()))
  }, [inputValue, options, values])

  return (
    <div className={`${S.select} ${S[name]} select`} ref={selectRef}>
      {label && <label>{label}</label>}
      {multi ? (
        <div
          className={`${show ? S.focus : ''} ${disabled ? S.disabled : ''}  ${S.select_input}`}
          onClick={handleShow}>
          <input
            ref={ref}
            name={name}
            disabled={isLoading}
            onClick={e => {
              // setShow(true)
              e.stopPropagation()
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (callback) {
                  callback(inputValue)
                  setShow(false)
                  setInputValue('')
                } else handleClick({ label: inputValue, value: inputValue })
              }
            }}
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={(event: any) => {
              setInputValue(event.target.value)
              setShow(true)
            }}
          />

          {isLoading ? (
            <div className={S.loading} />
          ) : (
            <ArrowDown2 size="16" color="#0a0a0a" variant="Outline" />
          )}
        </div>
      ) : (
        <div
          className={`${show ? S.focus : ''} ${disabled ? S.disabled : ''}  ${S.select_input}`}
          onClick={handleShow}>
          <input
            ref={ref}
            disabled={isLoading}
            className={S.input}
            type="text"
            value={inputValue}
            onClick={() => setInputValue('')}
            onChange={(event: any) => {
              setInputValue(event.target.value)
              setShow(true)
            }}
            style={{ opacity: show && inputValue.length > 0 ? 1 : 0 }}
          />
          {value?.label ? <span>{value?.label}</span> : placeholder}

          {isLoading ? (
            <div className={S.loading} />
          ) : (
            <ArrowDown2 size="16" color="#0a0a0a" variant="Outline" />
          )}
        </div>
      )}
      <AnimatePresence>
        {show &&
          (newOptions.filter(el => newValues.every(f => f.value !== el.value)).length > 0 ? (
            <motion.ul
              className={S.ul}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}>
              {newOptions
                .filter(el => newValues.every(f => f.value !== el.value))
                .map((option, index) => (
                  <li
                    className={option.value === value?.value ? S.active : ''}
                    role="button"
                    key={index}
                    onClick={() => {
                      setShow(false)
                      setInputValue('')
                      handleClick(option)
                    }}>
                    {option.label}
                  </li>
                ))}
            </motion.ul>
          ) : (
            <motion.ul
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={S.empty}>
              {multi ? (
                inputValue ? (
                  <p>Click item to add</p>
                ) : (
                  <p>No options</p>
                )
              ) : (
                <p>No options</p>
              )}
              {newOptions.filter(el => newValues.every(f => f.value !== el.value)).length === 0 &&
                inputValue &&
                multi && (
                  <li
                    role="button"
                    onClick={() => {
                      if (callback) {
                        callback(inputValue)
                        setShow(false)
                        setInputValue('')
                      } else handleClick({ label: inputValue, value: inputValue })
                    }}>
                    {inputValue}
                  </li>
                )}
            </motion.ul>
          ))}
      </AnimatePresence>
      {newValues && (
        <ul className={S.multi}>
          {newValues?.map((value, index) => (
            <li key={index}>
              {value.label}
              <span onClick={() => handleFilter(value)}>
                <CloseCircle size="16" color="#FFA93C" />
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default forwardRef(Select)
