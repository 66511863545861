import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

interface IUser {
  addExperienceStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  addExperienceError: string

  updateExperienceStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateExperienceError: string

  deleteExperienceStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  deleteExperienceError: string

  experienceData: any
  experienceStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  experienceError: string
}

const initialState: IUser = {
  addExperienceStatus: 'idle',
  addExperienceError: '',

  updateExperienceStatus: 'idle',
  updateExperienceError: '',

  deleteExperienceStatus: 'idle',
  deleteExperienceError: '',

  experienceData: [],
  experienceStatus: 'idle',
  experienceError: '',
}

export const UpdateExperience = createAsyncThunk(
  'experience/updateExperience',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/experience/update/${formData.id}`, {
        role: formData.role,
        company: formData.company,
        start_date: formData.start_date,
        end_date: formData.end_date,
        is_present: formData.is_present,
      })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetExperience())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteExperience = createAsyncThunk(
  'experience/deleteExperience',
  async (id: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.delete(`${baseUrl}/experience/remove/${id}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetExperience())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const AddExperience = createAsyncThunk(
  'experience/addExperience',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/experience/add`, formData)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetExperience())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetExperience = createAsyncThunk('experience/getExperience', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/experience/get`).then(response => response.data)
})

export const Experience = createSlice({
  name: 'experience',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AddExperience.fulfilled, state => {
      state.addExperienceStatus = 'succeeded'
      state.addExperienceError = ''
    }),
      builder.addCase(AddExperience.pending, state => {
        state.addExperienceStatus = 'pending'
      }),
      builder.addCase(AddExperience.rejected, (state, { error }) => {
        state.addExperienceStatus = 'failed'
        state.addExperienceError = error.message
      })

    builder.addCase(UpdateExperience.fulfilled, state => {
      state.updateExperienceStatus = 'succeeded'
      state.updateExperienceError = ''
    }),
      builder.addCase(UpdateExperience.pending, state => {
        state.updateExperienceStatus = 'pending'
      }),
      builder.addCase(UpdateExperience.rejected, (state, { error }) => {
        state.updateExperienceStatus = 'failed'
        state.updateExperienceError = error.message
      })

    builder.addCase(DeleteExperience.fulfilled, state => {
      state.deleteExperienceStatus = 'succeeded'
      state.deleteExperienceError = ''
    }),
      builder.addCase(DeleteExperience.pending, state => {
        state.deleteExperienceStatus = 'pending'
      }),
      builder.addCase(DeleteExperience.rejected, (state, { error }) => {
        state.deleteExperienceStatus = 'failed'
        state.deleteExperienceError = error.message
      })

    builder.addCase(GetExperience.fulfilled, (state, { payload }) => {
      state.experienceStatus = 'succeeded'
      state.experienceError = ''
      state.experienceData = payload.experience
    }),
      builder.addCase(GetExperience.pending, state => {
        state.experienceStatus = 'pending'
      }),
      builder.addCase(GetExperience.rejected, (state, { error }) => {
        state.experienceStatus = 'failed'
        state.experienceError = error.message
      })
  },
})

export default Experience.reducer
