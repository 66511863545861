import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

interface IUser {
  createIndustriesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createIndustriesError: string

  updateIndustriesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateIndustriesError: string

  industriesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  industriesError: string
  industriesData: any

  userIndustriesData: any
  userIndustriesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userIndustriesError: string
}

const initialState: IUser = {
  createIndustriesStatus: 'idle',
  createIndustriesError: '',

  updateIndustriesStatus: 'idle',
  updateIndustriesError: '',

  industriesStatus: 'idle',
  industriesError: '',
  industriesData: [],

  userIndustriesStatus: 'idle',
  userIndustriesError: '',
  userIndustriesData: [],
}

export const UpdateIndustries = createAsyncThunk(
  'industries/updateIndustries',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`${baseUrl}/user_industry`, { industries: formData })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(GetUserIndustries())
        // thunkAPI.dispatch(successMessage('Industries updated successfully'))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CreateIndustries = createAsyncThunk(
  'industries/createIndustries',
  async ({ name, industries }: { name: string; industries: string[] }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/industry`, { name })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(UpdateIndustries([...industries, Number(data.data.id)]))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetAllIndustries = createAsyncThunk(
  'industries/getAllIndustries',
  async ({ keyword, page }: { keyword: string; page: number }) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/industry/?keyword=${keyword}&per_page=100&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const GetUserIndustries = createAsyncThunk('industries/getUserIndustries', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/user_industry`).then(response => response.data)
})

export const Industries = createSlice({
  name: 'industries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetAllIndustries.fulfilled, (state, { payload }) => {
      state.industriesStatus = 'succeeded'
      state.industriesError = ''
      state.industriesData = payload
    }),
      builder.addCase(GetAllIndustries.pending, state => {
        state.industriesStatus = 'pending'
      }),
      builder.addCase(GetAllIndustries.rejected, (state, { error }) => {
        state.industriesStatus = 'failed'
        state.industriesError = error.message
      })

    builder.addCase(GetUserIndustries.fulfilled, (state, { payload }) => {
      state.userIndustriesStatus = 'succeeded'
      state.userIndustriesError = ''
      state.userIndustriesData = payload
    }),
      builder.addCase(GetUserIndustries.pending, state => {
        state.userIndustriesStatus = 'pending'
      }),
      builder.addCase(GetUserIndustries.rejected, (state, { error }) => {
        state.userIndustriesStatus = 'failed'
        state.userIndustriesError = error.message
      })

    builder.addCase(CreateIndustries.fulfilled, state => {
      state.createIndustriesStatus = 'succeeded'
      state.createIndustriesError = ''
    }),
      builder.addCase(CreateIndustries.pending, state => {
        state.createIndustriesStatus = 'pending'
      }),
      builder.addCase(CreateIndustries.rejected, (state, { error }) => {
        state.createIndustriesStatus = 'failed'
        state.createIndustriesError = error.message
      })

    builder.addCase(UpdateIndustries.fulfilled, state => {
      state.updateIndustriesStatus = 'succeeded'
      state.updateIndustriesError = ''
    }),
      builder.addCase(UpdateIndustries.pending, state => {
        state.updateIndustriesStatus = 'pending'
      }),
      builder.addCase(UpdateIndustries.rejected, (state, { error }) => {
        state.updateIndustriesStatus = 'failed'
        state.updateIndustriesError = error.message
      })
  },
})

export default Industries.reducer
