import { Fragment } from 'react'
import Link from 'next/link'

import { ArrowRight2 } from 'iconsax-react'
import { bcColor } from '@/utils'
import { useRouter } from 'next/router'

const Breadcrumb = ({
  arr,
  icon,
  iconLink,
}: {
  arr: { href?: string; title: string }[]
  icon?: any
  iconLink?: string
}) => {
  const router = useRouter()
  return (
    <div className="breadcrumb">
      {icon && iconLink ? (
        <Link href={iconLink}>{icon}</Link>
      ) : (
        <span role="button" onClick={() => router.back()}>
          {icon}
        </span>
      )}
      {icon && <ArrowRight2 size="12" color={bcColor} />}
      {arr?.map((item, index) => (
        <Fragment key={index}>
          {item.href ? (
            <Link href={item.href} key={index}>
              {item.title}
              <ArrowRight2 size="12" color={bcColor} />
            </Link>
          ) : (
            <span key={index}>{item.title}</span>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default Breadcrumb
