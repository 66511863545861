import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { errorMessage } from '../utils/generalMessage'

interface IExplore {
  allProsData: any
  allProsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  allProsError: string

  searchProsData: any
  searchProsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  searchProsError: string

  faveProsData: any
  faveProsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  faveProsError: string

  toggleFaveStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  toggleFaveError: string
}

const initialState: IExplore = {
  allProsData: {},
  allProsStatus: 'idle',
  allProsError: '',

  searchProsData: {},
  searchProsStatus: 'idle',
  searchProsError: '',

  faveProsData: {},
  faveProsStatus: 'idle',
  faveProsError: '',

  toggleFaveStatus: 'idle',
  toggleFaveError: '',
}

export const GetAllPros = createAsyncThunk('explore/getAllPros', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/pro`).then(response => response.data)
})

export const GetFavePros = createAsyncThunk('explore/getFavePros', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/pro/favorite`).then(response => response.data)
})

export const SearchPros = createAsyncThunk('explore/searchPros', async (keyword: string) => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/pro/search?keyword=${keyword}`).then(response => response.data)
})

export const ToggleFave = createAsyncThunk(
  'explore/toggleFave',
  async ({ slug, status }: { slug: string; status: boolean }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`/pro/favorite/${slug}`, {
        status,
      })
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(GetFavePros())
        // thunkAPI.dispatch(GetAllPros())

        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      debugger
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Explore = createSlice({
  name: 'explore',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(SearchPros.fulfilled, (state, { payload }) => {
      state.searchProsStatus = 'succeeded'
      state.searchProsError = ''
      state.searchProsData = payload
    }),
      builder.addCase(SearchPros.pending, state => {
        state.searchProsStatus = 'pending'
      }),
      builder.addCase(SearchPros.rejected, (state, { error }) => {
        state.searchProsStatus = 'failed'
        state.searchProsError = error.message
      })

    builder.addCase(GetAllPros.fulfilled, (state, { payload }) => {
      state.allProsStatus = 'succeeded'
      state.allProsError = ''
      state.allProsData = payload
    }),
      builder.addCase(GetAllPros.pending, state => {
        state.allProsStatus = 'pending'
      }),
      builder.addCase(GetAllPros.rejected, (state, { error }) => {
        state.allProsStatus = 'failed'
        state.allProsError = error.message
      })

    builder.addCase(GetFavePros.fulfilled, (state, { payload }) => {
      state.faveProsStatus = 'succeeded'
      state.faveProsError = ''
      state.faveProsData = payload
    }),
      builder.addCase(GetFavePros.pending, state => {
        state.faveProsStatus = 'pending'
      }),
      builder.addCase(GetFavePros.rejected, (state, { error }) => {
        state.faveProsStatus = 'failed'
        state.faveProsError = error.message
      })

    builder.addCase(ToggleFave.fulfilled, (state, action) => {
      const { status, slug } = action.meta.arg
      if (state.allProsData?.data) {
        const pro = state.allProsData.data.find(pro => pro.slug === slug)
        pro.favorited = status
      }

      state.toggleFaveStatus = 'succeeded'
      state.toggleFaveError = ''
    }),
      builder.addCase(ToggleFave.pending, state => {
        state.toggleFaveStatus = 'pending'
      }),
      builder.addCase(ToggleFave.rejected, (state, { error }) => {
        state.toggleFaveStatus = 'failed'
        state.toggleFaveError = error.message
      })
  },
})

export default Explore.reducer
