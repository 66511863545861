import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

interface IUser {
  addEducationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  addEducationError: string

  updateEducationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateEducationError: string

  deleteEducationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  deleteEducationError: string

  educationData: any
  educationStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  educationError: string
}

const initialState: IUser = {
  addEducationStatus: 'idle',
  addEducationError: '',

  updateEducationStatus: 'idle',
  updateEducationError: '',

  deleteEducationStatus: 'idle',
  deleteEducationError: '',

  educationData: [],
  educationStatus: 'idle',
  educationError: '',
}

export const UpdateEducation = createAsyncThunk(
  'education/updateEducation',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/education/update/${formData.id}`, {
        school: formData.school,
        degree: formData.degree,
        start_date: formData.start_date,
        end_date: formData.end_date,
        grade: formData.grade,
      })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetEducation())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteEducation = createAsyncThunk(
  'education/deleteEducation',
  async (id: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.delete(`${baseUrl}/education/remove/${id}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetEducation())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const AddEducation = createAsyncThunk(
  'education/addEducation',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/education/add`, formData)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetEducation())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetEducation = createAsyncThunk('education/getEducation', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/education/get`).then(response => response.data)
})

export const Education = createSlice({
  name: 'education',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AddEducation.fulfilled, state => {
      state.addEducationStatus = 'succeeded'
      state.addEducationError = ''
    }),
      builder.addCase(AddEducation.pending, state => {
        state.addEducationStatus = 'pending'
      }),
      builder.addCase(AddEducation.rejected, (state, { error }) => {
        state.addEducationStatus = 'failed'
        state.addEducationError = error.message
      })

    builder.addCase(UpdateEducation.fulfilled, state => {
      state.updateEducationStatus = 'succeeded'
      state.updateEducationError = ''
    }),
      builder.addCase(UpdateEducation.pending, state => {
        state.updateEducationStatus = 'pending'
      }),
      builder.addCase(UpdateEducation.rejected, (state, { error }) => {
        state.updateEducationStatus = 'failed'
        state.updateEducationError = error.message
      })

    builder.addCase(DeleteEducation.fulfilled, state => {
      state.deleteEducationStatus = 'succeeded'
      state.deleteEducationError = ''
    }),
      builder.addCase(DeleteEducation.pending, state => {
        state.deleteEducationStatus = 'pending'
      }),
      builder.addCase(DeleteEducation.rejected, (state, { error }) => {
        state.deleteEducationStatus = 'failed'
        state.deleteEducationError = error.message
      })

    builder.addCase(GetEducation.fulfilled, (state, { payload }) => {
      state.educationStatus = 'succeeded'
      state.educationError = ''
      state.educationData = payload.education
    }),
      builder.addCase(GetEducation.pending, state => {
        state.educationStatus = 'pending'
      }),
      builder.addCase(GetEducation.rejected, (state, { error }) => {
        state.educationStatus = 'failed'
        state.educationError = error.message
      })
  },
})

export default Education.reducer
