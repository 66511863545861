import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  revision: process.env.NEXT_PUBLIC_APP_REVISION,
}

export const debugService = createSlice({
  name: 'debug',
  initialState,
  reducers: {},
})

export default debugService.reducer
