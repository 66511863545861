import Image from 'next/image'
import S from './BecomePro.module.scss'
import { Button } from '..'
import { useRouter } from 'next/router'

const Pro = () => {
  const router = useRouter()
  return (
    <div className={S.pro}>
      <Image src="/badge.svg" width="40" height="51" alt="badge" />
      <h4>Become a PRO!</h4>
      <p>
        By becoming a PRO, you are able to monetize your time, skills & knowledge. People pay to
        talk to you & learn from you.
      </p>
      <Button
        type={'primary'}
        className={S.pro_button}
        onClick={() => router.push('/become-a-pro')}>
        Get started
      </Button>
    </div>
  )
}

export default Pro
