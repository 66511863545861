import axios from 'axios'
import cookie from 'js-cookie'

export const setCookie = (key: string, value: string) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 7,
      sameSite: 'Strict',
      path: '/',
    })
  }
}

export const getCookie = (key: string, req: any) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req)
}

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key, { path: '/', expires: 7 })
  }
}

const getCookieFromBrowser = (key: string) => {
  return cookie.get(key)
}

const getCookieFromServer = (key: string, req: any) => {
  if (!req.headers?.cookie) {
    return ''
  }
  const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`))
  if (!rawCookie) {
    return ''
  }
  return rawCookie.split('=')[1]
}

export const setAuthorizationHeader = () => {
  const token = getCookie('access_token', { req: {} })
  const headerToken = `Bearer ${token}`
  axios.defaults.headers.common['Authorization'] = headerToken
}

export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

export const isTokenExpired = (text: string) => {
  let expired = false
  if (text === 'Expired token') {
    expired = true
  } else if (text === 'Invalid token') {
    expired = true
  } else if (text === 'No token provided') {
    expired = true
  } else {
    expired = false
  }
  return expired
}
