/* eslint-disable no-unused-vars */
import { baseUrl, getCookie } from '@/utils/session'
import { useState } from 'react'

const useFileUpload = ({
  upload_type,
  onSuccess,
  onFailure,
}: {
  upload_type: 'PROFILE' | 'BROADCAST' | 'GENERAL'

  onSuccess?: (e) => void
  onFailure?: (err: any) => void
}) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'succeeded' | 'failed'>('idle')
  const upload = async (file: Blob): Promise<string> => {
    try {
      setStatus('pending')

      const fd = new FormData()
      fd.append('file', file)
      fd.append('upload_type', upload_type)

      const token = getCookie('access_token', { req: {} })
      const headerToken = `Bearer ${token}`

      const requestOptions = {
        method: 'POST',
        body: fd,
        headers: { authorization: headerToken },
      }

      try {
        return await fetch(`${baseUrl}/misc/upload`, requestOptions).then(async response => {
          if (response?.ok) {
            const data = await response.json()
            setStatus('succeeded')
            if (onSuccess) {
              onSuccess(data.file_url)
            } else return data.file_url
          } else {
            setStatus('failed')
            onFailure?.(response.statusText)
            return Promise.reject(response.statusText)
          }
        })
      } catch (error) {
        setStatus('failed')
        onFailure?.(error)
        return Promise.reject(error)
      }
    } catch (e) {
      onFailure?.(e)
      setStatus('failed')
      return Promise.reject(e)
    }
  }

  return { status, upload }
}

export default useFileUpload
