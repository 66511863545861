import { useState } from 'react'

import { capitalizeFirstLetter, numberToCurrency, userTimezone } from '@/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'
import { AnimatePresence } from 'framer-motion'

import Payout from './Payout'
import { useMediaQuery } from '@/hooks'

import S from './Transaction.module.scss'

const Transaction = ({ transaction }) => {
  const isMobile = useMediaQuery('(max-width: 480px)')
  const zonedDate = utcToZonedTime(new Date(transaction.created_at), userTimezone)
  const date = format(zonedDate, 'P, p')
  const [show, setShow] = useState(false)

  const amountDisplay = numberToCurrency(
    transaction.amount / Math.max(transaction.rate ?? 1, 1),
    'USD'
  )

  const ExtraInfo =
    transaction.currency === 'USD' ? null : (
      <span style={{ fontSize: '0.6em' }}>
        ({numberToCurrency(transaction.amount, transaction.currency)})
      </span>
    )

  return (
    <>
      <AnimatePresence>
        {show && (
          <Payout
            handleShow={() => setShow(false)}
            transaction={transaction}
            date={date}
            extraInfo={ExtraInfo}
            amountDisplay={amountDisplay}
          />
        )}
      </AnimatePresence>
      {isMobile ? (
        <div className={S.mobile} onClick={() => setShow(true)}>
          <div className={S.mobileLeft}>
            {transaction.transaction_type === 'DEBIT' ? (
              <h4 className={S.debit}>
                {amountDisplay}
                {ExtraInfo}
              </h4>
            ) : (
              <h4 className={S.credit}>
                {amountDisplay}
                {ExtraInfo}
              </h4>
            )}

            <p>{transaction.description}</p>
            <p>{date}</p>
          </div>

          <div className={S.mobileRight}>
            {transaction.status === 'PENDING' && (
              <span className={S.pending}>{transaction.status?.toLowerCase()}</span>
            )}

            {transaction.status === 'FAILED' && (
              <span className={S.failed}>{transaction.status?.toLowerCase()}</span>
            )}

            {transaction.status === 'SUCCESS' && (
              <span className={S.completed}>{transaction.status?.toLowerCase()}</span>
            )}
            <p>{transaction.reference}</p>
          </div>
        </div>
      ) : (
        <tr role="button" className={S.transaction} onClick={() => setShow(true)}>
          <td>
            <p>{date}</p>
          </td>

          <td>
            <p>{transaction.description}</p>
          </td>

          <td>
            <p>{transaction.reference}</p>
          </td>

          <td>
            {transaction.transaction_type === 'DEBIT' ? (
              <h4 className={S.debit}>
                {amountDisplay}
                {ExtraInfo}
              </h4>
            ) : (
              <h4 className={S.credit}>
                {amountDisplay}
                {ExtraInfo}
              </h4>
            )}
          </td>

          <td>
            {transaction.status === 'PENDING' && (
              <span className={S.pending}>{capitalizeFirstLetter(transaction.status)}</span>
            )}

            {transaction.status === 'FAILED' && (
              <span className={S.failed}>{capitalizeFirstLetter(transaction.status)}</span>
            )}

            {transaction.status === 'SUCCESS' && (
              <span className={S.completed}>{capitalizeFirstLetter(transaction.status)}</span>
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export default Transaction
