import { Timer1 } from 'iconsax-react'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { addMinutes, intervalToDuration, sub } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import ClientOnlyPortal from '../ClientPortal'
import { isDevelopment } from '@/utils'

const Countdown = ({ startDatetime, duration }: { startDatetime: Date; duration: number }) => {
  const [stopCount, setStopCount] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)
  const [minutes, setMinutes] = useState<number | null>(null)

  const meetingEndtime = useMemo(() => {
    const start = startDatetime
    return addMinutes(start, Number(duration))
  }, [duration, startDatetime])

  const showRef = useRef(false)

  const [timeString, setTimeString] = useState('00:00')

  useEffect(() => {
    const interval = setInterval(() => {
      const start = sub(new Date(), { seconds: 1 })

      const { minutes, seconds } = intervalToDuration({
        start,
        end: meetingEndtime,
      })

      setMinutes(minutes)
      setTimeString([minutes, seconds.toString().padStart(2, '0')].join(':'))

      if (minutes < 5) {
        setStopCount(true)
        !showRef.current && handleShow()
      }

      if (minutes == 0 && seconds == 0) {
        clearInterval(interval)
        window.location.pathname = '/pickt-meet'
        return
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [meetingEndtime, setTimeString])

  const handleShow = () => {
    showRef.current = true
    setShowPopUp(true)
  }

  const portalRef = useRef(null)
  useEffect(() => {
    portalRef.current = document.querySelector('#__next')
  }, [])

  return (
    <Fragment>
      <div
        role={isDevelopment ? 'button' : null}
        onClick={() => (isDevelopment ? setShowPopUp(!showPopUp) : null)}
        className={stopCount ? 'timeout countdown' : 'countdown'}>
        <Timer1 size="20" color="#ffffff" variant="Bold" />
        <span>{timeString}</span>
      </div>
      <AnimatePresence>
        {showPopUp && (
          <ClientOnlyPortal>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { type: 'tween', duration: 0.25 } }}
              exit={{ opacity: 0, transition: { type: 'tween', duration: 0.25 } }}
              drag
              dragConstraints={portalRef}
              dragMomentum={false}
              whileHover={{ cursor: 'grab' }}
              whileDrag={{ cursor: 'grabbing' }}
              className="countdownModal">
              <div className="content">
                <span role="button" onClick={() => setShowPopUp(!showPopUp)}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill="#6E5ADE" />
                    <path
                      d="M20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.1699 22.83L22.8299 17.17"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.8299 22.83L17.1699 17.17"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>

                <div>
                  <svg
                    width="49"
                    height="48"
                    viewBox="0 0 49 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.7885 4.13464C18.9361 3.76738 19.3091 3.59883 19.7044 3.57947C20.4117 3.54483 21.8408 3.5 24.5 3.5C27.1592 3.5 28.5883 3.54483 29.2956 3.57947C29.6909 3.59884 30.0639 3.76738 30.2115 4.13464C30.3576 4.49804 30.5 5.08656 30.5 6C30.5 6.91344 30.3576 7.50196 30.2115 7.86536C30.0639 8.23262 29.6909 8.40116 29.2956 8.42053C28.8523 8.44224 28.1255 8.46795 26.99 8.48412C26.9962 9.06085 27 9.72868 27 10.5C27 13.1592 26.9552 14.5883 26.9205 15.2956C26.9012 15.6909 26.7326 16.0639 26.3654 16.2115C26.002 16.3576 25.4134 16.5 24.5 16.5C23.5866 16.5 22.998 16.3576 22.6346 16.2115C22.2674 16.0639 22.0988 15.6909 22.0795 15.2956C22.0448 14.5883 22 13.1592 22 10.5C22 9.72868 22.0038 9.06085 22.01 8.48412C20.8745 8.46795 20.1477 8.44224 19.7044 8.42053C19.3091 8.40116 18.9361 8.23261 18.7885 7.86536C18.6424 7.50196 18.5 6.91344 18.5 6C18.5 5.08656 18.6424 4.49804 18.7885 4.13464Z"
                      fill="#E67290"
                    />
                    <path
                      d="M41.5793 15.5597C41.9142 15.1747 42.022 14.664 41.7671 14.2219C41.4709 13.7083 40.902 12.9163 39.8142 11.8284C38.7263 10.7406 37.9342 10.1717 37.4207 9.8755C36.9786 9.6206 36.4678 9.72836 36.0829 10.0633C35.5079 10.5636 34.4715 11.5142 32.7431 13.2426C31.0146 14.9711 30.064 16.0074 29.5637 16.5824C29.2288 16.9674 29.121 17.4782 29.3759 17.9202C29.6721 18.4338 30.241 19.2259 31.3289 20.3137C32.4167 21.4015 33.2088 21.9705 33.7224 22.2666C34.1644 22.5216 34.6752 22.4138 35.0601 22.0789C35.6352 21.5786 36.6715 20.628 38.3999 18.8995C40.1284 17.171 41.079 16.1347 41.5793 15.5597Z"
                      fill="#E67290"
                    />
                    <circle cx="24.5" cy="27" r="17" fill="#F3F1F6" />
                    <path
                      d="M28.4976 14.9215C28.5618 13.9718 27.323 13.6366 26.9084 14.4915C25.6409 17.1053 23.9738 20.6098 22.7421 23.44C21.7397 23.9231 20.945 24.8296 20.635 25.9981C20.0727 28.1174 21.318 30.2958 23.4164 30.8636C25.5148 31.4315 27.6717 30.1738 28.234 28.0545C28.544 26.8861 28.3046 25.6997 27.6756 24.7751C28.0101 21.7022 28.3012 17.8252 28.4976 14.9215Z"
                      fill="#E67290"
                    />
                  </svg>

                  {timeString}
                </div>
                <p>You have less than {minutes + 1} minutes remaining</p>
              </div>
            </motion.div>
          </ClientOnlyPortal>
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default Countdown
