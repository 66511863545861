import { Calendar, Clock, TicketStar } from 'iconsax-react'
import Image from 'next/image'
import Link from 'next/link'
import S from './Broadcast.module.scss'

const Broadcast = ({ broadcast }) => {
  return (
    <Link href={`/broadcast/${broadcast.slug}`} className={S.broadcast}>
      <Image src={broadcast.cover} width="128" height="128" alt="" />
      <div>
        <h3>{broadcast.title}</h3>
        <div className={S.broadcast_list}>
          <div>
            <Clock size="20" color="#6BAFD9" />
            <span>
              {broadcast.meeting_start_time} - {broadcast.meeting_end_time}
            </span>
          </div>
          <div>
            <Calendar size="20" color="#6BAFD9" />
            {broadcast.meeting_date}
          </div>
          {broadcast.available_tickets && (
            <div>
              <TicketStar size="20" color="#6BAFD9" />
              {broadcast.available_tickets - broadcast.tickets_sold} tickets left
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default Broadcast
