import { AnimatePresence } from 'framer-motion'
import { Building, Edit2, Trash } from 'iconsax-react'
import { useState } from 'react'
import S from './Experience.module.scss'
import DeleteExperience from './DeleteExperience'
import AddExperience from './AddExperience'
import { useMediaQuery } from '@/hooks'

interface IExperience {
  experience: any
}

const Experience = ({ experience }: IExperience) => {
  const isTablet = useMediaQuery('(max-width: 768px)')
  const [show, setShow] = useState<boolean>(false)
  const [showAdd, setShowAdd] = useState<boolean>(false)

  return (
    <>
      <AnimatePresence>
        {show && <DeleteExperience experience={experience} handleModal={() => setShow(false)} />}
      </AnimatePresence>
      <AnimatePresence>
        {showAdd && (
          <AddExperience
            experience={experience}
            handleDelete={() => setShow(true)}
            handleModal={() => setShowAdd(false)}
          />
        )}
      </AnimatePresence>

      <li
        className={S.list}
        onClick={() => {
          isTablet ? setShowAdd(true) : null
        }}>
        <div className={S.list_left}>
          <span>
            <Building size="28" color="#E67290" variant="Bold" />
          </span>
          <div>
            <h3>{experience.company}</h3>
            <p>{experience.role}</p>
          </div>
        </div>
        <div className={S.list_right}>
          {experience.is_present ? (
            <span className={S.present}>PRESENT</span>
          ) : (
            <span className={S.time}>
              {experience.start_date?.substring(3, 7)} - {experience.end_date?.substring(3, 7)}
            </span>
          )}
          {!isTablet && (
            <span className={S.icon} role="button" onClick={() => setShowAdd(true)}>
              <Edit2 size="20" color="#b1aec6" />
            </span>
          )}
          {!isTablet && (
            <span className={S.icon} role="button" onClick={() => setShow(true)}>
              <Trash size="20" color="#b1aec6" />
            </span>
          )}
        </div>
      </li>
    </>
  )
}

export default Experience
