import { createSlice } from '@reduxjs/toolkit'

export interface IMeetingData {
  proData: any
  meetingData: any
  transactionData: any
  meetingSteps: number
}

const initialState: IMeetingData = {
  proData: {},
  meetingData: {},
  transactionData: {
    reference: '',
  },
  meetingSteps: 0,
}

export const meetingDataSlice = createSlice({
  name: 'meetingData',
  initialState,
  reducers: {
    setProData: (state, { payload }) => {
      state.proData = payload
    },
    setMeetingData: (state, { payload }) => {
      state.meetingData = payload
    },
    setTransactionData: (state, { payload }) => {
      state.transactionData = payload
    },
    updateStep: (state, { payload }) => {
      state.meetingSteps = payload
    },
  },
})

export const { setProData, setMeetingData, setTransactionData, updateStep } =
  meetingDataSlice.actions

export default meetingDataSlice.reducer
