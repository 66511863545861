import { createSlice } from '@reduxjs/toolkit'

interface IModals {
  isProfileDetailsOpen: boolean
  isProfileInterestsOpen: boolean
  isProfileSkillsOpen: boolean
  isProfileIndustriesOpen: boolean
  isProfileRatesOpen: boolean

  isAddScheduleOpen: boolean
  isOverrideOpen: boolean
  isRescheduleModalOpen: boolean
  isCancelModalOpen: boolean

  isWithdrawalSetupOpen: boolean
  isWithdrawalDeleteOpen: boolean

  // isFavouriteOpen: boolean
  // isFavouriteUser: string
}

const initialState: IModals = {
  isProfileDetailsOpen: false,
  isProfileInterestsOpen: false,
  isProfileIndustriesOpen: false,
  isProfileSkillsOpen: false,
  isProfileRatesOpen: false,

  isAddScheduleOpen: false,
  isOverrideOpen: false,
  isRescheduleModalOpen: false,
  isCancelModalOpen: false,

  isWithdrawalSetupOpen: true,
  isWithdrawalDeleteOpen: false,

  // isFavouriteOpen: false,
  // isFavouriteUser: '',
}

export const modalService = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    // Profile Modal
    openProfileDetails: state => {
      state.isProfileDetailsOpen = true
    },
    closeProfileDetails: state => {
      state.isProfileDetailsOpen = false
    },

    // Profile Interests Modal
    openProfileInterests: state => {
      state.isProfileInterestsOpen = true
    },
    closeProfileInterests: state => {
      state.isProfileInterestsOpen = false
    },

    // Profile Skills Modal
    openProfileSkills: state => {
      state.isProfileSkillsOpen = true
    },
    closeProfileSkills: state => {
      state.isProfileSkillsOpen = false
    },

    // Profile Industries Modal
    openProfileIndustries: state => {
      state.isProfileIndustriesOpen = true
    },
    closeProfileIndustries: state => {
      state.isProfileIndustriesOpen = false
    },

    // Profile Rates Modal
    openProfileRates: state => {
      state.isProfileRatesOpen = true
    },
    closeProfileRates: state => {
      state.isProfileRatesOpen = false
    },

    // Add Schedules Modal
    openAddSchedule: state => {
      state.isAddScheduleOpen = true
    },
    closeAddSchedule: state => {
      state.isAddScheduleOpen = false
    },

    // Add Override Modal
    openOverrideModal: state => {
      state.isOverrideOpen = true
    },
    closeOverrideModal: state => {
      state.isOverrideOpen = false
    },

    // Add Reschedule Modal
    openRescheduleModal: state => {
      state.isRescheduleModalOpen = true
    },
    closeRescheduleModal: state => {
      state.isRescheduleModalOpen = false
    },

    // Add Cancel Modal
    openCancelModal: state => {
      state.isCancelModalOpen = true
    },
    closeCancelModal: state => {
      state.isCancelModalOpen = false
    },

    // Add Withdrawal Setup Modal
    openWithdrawalSetup: state => {
      state.isWithdrawalSetupOpen = true
    },
    closeWithdrawalSetup: state => {
      state.isWithdrawalSetupOpen = false
    },

    // Add Withdrawal Delete Modal
    openWithdrawalDelete: state => {
      state.isWithdrawalDeleteOpen = true
    },
    closeWithdrawalDelete: state => {
      state.isWithdrawalDeleteOpen = false
    },

    // openFavouriteAlert: (state, { payload }) => {
    //   state.isFavouriteUser = payload
    //   state.isFavouriteOpen = !state.isFavouriteOpen
    // },
  },
})

export const {
  openProfileDetails,
  closeProfileDetails,
  openProfileInterests,
  closeProfileInterests,
  openProfileSkills,
  closeProfileSkills,
  openProfileIndustries,
  closeProfileIndustries,
  openProfileRates,
  closeProfileRates,

  openAddSchedule,
  closeAddSchedule,
  closeOverrideModal,
  openOverrideModal,
  closeRescheduleModal,
  openRescheduleModal,
  closeCancelModal,
  openCancelModal,

  closeWithdrawalSetup,
  openWithdrawalSetup,
  closeWithdrawalDelete,
  openWithdrawalDelete,

  // openFavouriteAlert,
} = modalService.actions

export default modalService.reducer
