import S from './Preloader.module.scss'
import { motion } from 'framer-motion'
import { useGeneralCalls } from '@/hooks'
import { isDevelopment } from '@/utils'

const Preloader = () => {
  useGeneralCalls()

  const childText = {
    initial: {
      y: '120%',
    },
    animate: {
      y: 0,
    },
  }

  const parentText = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1.5,
        type: 'tween',
        delayChildren: 0.25,
        staggerChildren: 0.1,
      },
    },
  }

  if (isDevelopment) return

  return (
    <motion.section
      className={S.preloader}
      animate={{
        opacity: 0,
        transition: {
          type: 'tween',
          delay: 2,
          duration: 0.35,
        },
      }}>
      <motion.h1 variants={parentText} initial="initial" animate="animate">
        {[...'Pickt.'].map((text, index) => (
          <motion.span key={index} variants={childText}>
            {text}
          </motion.span>
        ))}
      </motion.h1>
    </motion.section>
  )
}

export default Preloader
