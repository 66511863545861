import React from 'react'
import s from './Error.module.scss'
import { Button } from '@/shared'
import captureSentryError from '@/utils/captureSentryError'
// import Router from 'next/router'

class ErrorBoundary extends React.Component {
  constructor(props: {} | Readonly<{}>) {
    super(props)

    this.state = { hasError: false, error: '' }
  }
  static getDerivedStateFromError(error: any) {
    console.log(error)

    return { hasError: true }
  }
  componentDidCatch(error: any, errorInfo: any) {
    console.log({ error, errorInfo })
    captureSentryError(error as Error)
    // this.setState({ error: error.message })
  }
  render() {
    if (this.state['hasError']) {
      return (
        <div className={s.error}>
          <h1>Oops, there was an error!</h1>
          {/* <p>{this.state['error']}</p> */}
          <div>
            <Button
              className={s.btn}
              type="primary"
              onClick={() => this.setState({ hasError: false })}>
              Try again?
            </Button>
            {/* <Button className={s.btn} type="secondary" onClick={() => Router.back()}>
              Go Back
            </Button> */}
          </div>
        </div>
      )
    }

    // Return children components in case of no error

    return this.props['children']
  }
}

export default ErrorBoundary
