import axios, { AxiosError, AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import RefreshTokenService from '@/redux/slices/auth/refreshToken'
import { getCookie, setCookie, baseUrl } from './session'

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean
  }
}

export const instance: AxiosInstance = axios.create({
  baseURL: baseUrl,
})

axiosRetry(instance, {
  retries: 0,
  retryCondition: response => {
    return response.status > 400
  },
  retryDelay: axiosRetry.exponentialDelay,
})

// for multiple requests
let isRefreshing: boolean = false
let failedQueue: any[] = []

const processQueue = (error: any, token: string | undefined = undefined) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

instance.interceptors.request.use(
  config => {
    if (config.headers === undefined) {
      config.headers = {}
    }

    const token = getCookie('access_token', { req: {} })

    if (token && token !== 'undefined') {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    return response
  },
  async (error: AxiosError) => {
    const { config } = error
    const originalRequest = config

    if (originalRequest && originalRequest.url !== '/auth/signin' && error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then(token => {
              if (originalRequest.headers === undefined) {
                originalRequest.headers = {}
              }
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return instance(originalRequest)
            })
            .catch(err => {
              return Promise.reject(err)
            })
        }

        originalRequest._retry = true
        isRefreshing = true

        try {
          const access_token = await RefreshTokenService()
          // Update access token in cookie
          setCookie('access_token', access_token)
          processQueue(null, access_token)
          return instance(originalRequest)
        } catch (_err) {
          processQueue(_err, undefined)
          return Promise.reject(_err)
        } finally {
          isRefreshing = false
        }
      }

      if (error.response.status === 429) {
        // window.location.reload()
      }
    }

    return Promise.reject(error)
  }
)

const http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  patch: instance.patch,
  delete: instance.delete,
  req: instance.defaults,
}

export default http
