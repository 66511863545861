import { ISelect, months, years } from '@/utils/mock'
import { useMemo } from 'react'

const UseDateArray = ({
  startYear,
  endYear,
  startMonth,
}: {
  startYear: ISelect
  endYear: ISelect
  startMonth: ISelect
}) => {
  const presentMonth = months[new Date().getMonth()].value.padStart(2, '0')
  const endYearArray = useMemo(() => {
    if (startYear) {
      return years.filter(year => Number(year?.value) >= Number(startYear?.value))
    }
  }, [startYear])

  const endMonthArray = useMemo(() => {
    if (startYear && new Date().getFullYear() === Number(startYear?.value)) {
      return months.filter(
        month => Number(month?.value) <= Number(startMonth?.value.padStart(2, '0'))
      )
    }
    if (endYear && new Date().getFullYear() === Number(endYear?.value)) {
      return months.filter(month => Number(month?.value) <= Number(presentMonth))
    }
    return months
  }, [endYear, presentMonth, startMonth, startYear])

  const startMonthArray = useMemo(() => {
    if (startYear && new Date().getFullYear() === Number(startYear?.value)) {
      return months.filter(month => Number(month?.value) <= Number(presentMonth))
    }
    return months
  }, [startYear, presentMonth])

  return {
    endYearArray,
    endMonthArray,
    startMonthArray,
  }
}

export default UseDateArray
