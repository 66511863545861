import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import { Call, Heart } from 'iconsax-react'

import { useAppDispatch } from '@/redux/hooks'
import { ToggleFave } from '@/redux/slices/Explore/explore'

import { ceil, minToHr, numberToCurrencyNoDecimals } from '@/utils'
import countries from '@/utils/countries'

import S from './Card.module.scss'
import { AnimatePresence, motion } from 'framer-motion'

export const Card = ({ proData }: any) => {
  const dispatch = useAppDispatch()

  const country = useMemo(() => {
    if (proData?.country && countries.length > 0) {
      return countries.filter(
        country => country.value.toUpperCase() == proData?.country.toUpperCase()
      )[0]?.code
    } else return 'NG'
  }, [proData])

  const imgRef = useRef(null)

  const favorited = proData.favorited
  const [showNotification, setShowNotification] = useState(false)

  const toggle = useCallback((value) => {
    dispatch(ToggleFave({
      slug: proData.slug,
      status: value,
    }))
    setShowNotification(value)
    setTimeout(() => {
      setShowNotification(false)
    }, 1000)
  }, [proData])

  const toggleFavId = 'toggleFave'
  const handlefave = e => {
    e.stopPropagation()
    e.preventDefault()
    if (e.currentTarget.id !== toggleFavId) {
      return
    }
    if (favorited) {
      toggle(false)
    } else {
      toggle(true)
    }
  }

  return (
    <Link className={S.card} href={`/pro/${proData.slug}`}>
      <span
        id={toggleFavId}
        onClick={handlefave}
      >
        <Heart
          size="20"
          color={favorited ? '#EB4F4F' : '#FFFFFF'}
          variant={favorited ? 'Bold' : 'Linear'}
        />
        <AnimatePresence>
          {showNotification && (
            <motion.div
              style={{ pointerEvents: 'none' }}
              initial={{ opacity: 0, y: '100%' }}
              exit={{ opacity: 0, y: '0%' }}
              animate={{ opacity: 1, y: '0%' }}
              className={S.card_saved}>
              <p>Saved to favourites.</p>
            </motion.div>
          )}
        </AnimatePresence>
      </span>

      <picture>
        <source ref={imgRef} srcSet={proData.photo} width="201" height="252" />
        <img
          src={'/avatar.svg'}
          alt=""
          loading="lazy"
          width="201"
          height="252"
          onError={(e: any) => {
            imgRef.current.srcset = e.target.src
          }}
        />
      </picture>

      <div className={S.card_main}>
        <h4>
          <span> {proData.name}</span>
          <img
            height="10"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
            alt={proData?.country}
          />
        </h4>
        <small>
          <Call size="14" color="#E67290" variant="Outline" />
          From{' '}
          {numberToCurrencyNoDecimals(
            ceil(
              Math.min(
                minToHr(Number(proData.voice_call_rate)),
                minToHr(Number(proData.video_call_rate)),
                minToHr(Number(proData.chat_rate))
              )
            ),
            2
          )}
          /min
        </small>
      </div>
    </Link>
  )
}

export default Card
