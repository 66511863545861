import { removeCookie } from '@/utils/session'
import { createSlice } from '@reduxjs/toolkit'
import Router from 'next/router'

export interface CheckUser {
  isUser: boolean
}

const initialState: CheckUser = {
  isUser: false,
}

export const checkUserSlice = createSlice({
  name: 'checkUser',
  initialState,
  reducers: {
    logoutUser: state => {
      removeCookie('access_token')
      removeCookie('user_id')
      removeCookie('user_email')
      Router.reload()
      // Router.push('/', undefined, { shallow: true })
      state.isUser = false
    },
    authUser: state => {
      state.isUser = true
    },
  },
})

export const { logoutUser, authUser } = checkUserSlice.actions

export default checkUserSlice.reducer
