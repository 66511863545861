import { slugify } from '@/utils/slugify'
import { Book1, Profile2User, User, VideoPlay } from 'iconsax-react'
import Link from 'next/link'
import S from './Product.module.scss'

const Product = ({
  product,
}: {
  product: {
    title: string
    date: string
    type: 'ebook' | 'podcast'
    img: string
    fee: string
  }
}) => {
  return (
    <Link href={`/products/${slugify(product.title)}`} className={S.product}>
      <img src={product.img} alt="" />
      <div className={S.product_main}>
        <h2>{product.title}</h2>
        <div className={S.product_main_bottom}>
          <p>{product.date}</p>
          <div>
            <p>
              {product.type === 'ebook' ? (
                <>
                  <span>
                    <Book1 size="14" color="#ADB3BA" variant="Bold" />
                    E-book
                  </span>
                  <span>
                    <User size="14" color="#ADB3BA" variant="Bold" />
                    Author
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Profile2User size="14" color="#ADB3BA" variant="Bold" />
                    Short Course
                  </span>
                  <span>
                    <VideoPlay size="14" color="#ADB3BA" variant="Bold" />
                    Co-creator
                  </span>
                </>
              )}
            </p>
            <span>{product.fee}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Product
