import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { IUserTransactions } from '@/utils/mock'

interface IWallet {
  walletData: {
    available_balance: number
    wallet_balance: number
  }
  walletStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  walletError: string

  userTransactionsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userTransactionsError: string
  userTransactionsData: {
    data: any[]
    links: any
    meta: any
  }
}

const initialState: IWallet = {
  walletStatus: 'idle',
  walletError: '',
  walletData: {
    available_balance: 0,
    wallet_balance: 0,
  },
  userTransactionsStatus: 'idle',
  userTransactionsError: '',
  userTransactionsData: {
    data: [],
    links: null,
    meta: null,
  },
}

export const GetWallet = createAsyncThunk('wallet/walletData', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/transaction/balance`).then(response => response.data)
})

export const GetUserTransactions = createAsyncThunk(
  'wallet/userTransactionsData',
  async ({ status, payment_type, transaction_type, page }: IUserTransactions) => {
    setAuthorizationHeader()
    return await http
      .get(
        `${baseUrl}/transaction/?status=${status}&payment_type=${payment_type}&transaction_type=${transaction_type}&per_page=10&page=${
          1 * page
        }`
      )
      .then(response => response.data)
  }
)

export const Wallet = createSlice({
  name: 'Wallet',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetWallet.fulfilled, (state, { payload }) => {
      state.walletStatus = 'succeeded'
      state.walletError = ''
      state.walletData = payload
    }),
      builder.addCase(GetWallet.pending, state => {
        state.walletStatus = 'pending'
      }),
      builder.addCase(GetWallet.rejected, (state, { error }) => {
        state.walletStatus = 'failed'
        state.walletError = error.message
      })

    builder.addCase(GetUserTransactions.fulfilled, (state, { payload }) => {
      state.userTransactionsStatus = 'succeeded'
      state.userTransactionsError = ''
      state.userTransactionsData = payload
    }),
      builder.addCase(GetUserTransactions.pending, state => {
        state.userTransactionsStatus = 'pending'
      }),
      builder.addCase(GetUserTransactions.rejected, (state, { error }) => {
        state.userTransactionsStatus = 'failed'
        state.userTransactionsError = error.message
      })
  },
})

export default Wallet.reducer
