import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import login from './slices/auth/loginUser'
import signUp from './slices/auth/signUpUser'
import checkUser from './slices/auth/userAuth'
import resetPassword from './slices/auth/resetPassword'
import verification from './slices/auth/verification'
import invitee from './slices/auth/invitee'
import socialAuth from './slices/auth/socialAuth'

import updateUser from './slices/userData/updateUser'
import interests from './slices/userData/interests'
import socials from './slices/userData/socials'
import experience from './slices/userData/experience'
import education from './slices/userData/education'
import credentials from './slices/userData/credentials'

import generalMessage from './slices/utils/generalMessage'
import modalService from './slices/utils/modalService'
import meetingData from './slices/utils/meetingData'
import searchUsers from './slices/utils/searchUsers'
import miscellaneous from './slices/utils/miscellaneous'

import schedule from './slices/interaction/schedule'
import meeting from './slices/interaction/meeting'
import proUserData from './slices/BecomeAPro/profile'
import wallet from './slices/interaction/wallet'
import notes from './slices/interaction/notes'
import broadcast from './slices/interaction/broadcast'

import payment from './slices/payment'
import debug from './slices/debug'

import skills from './slices/BecomeAPro/skills'
import industries from './slices/BecomeAPro/industries'
import profile from './slices/BecomeAPro/profile'

import notifications from './slices/settings/notification'
import password from './slices/settings/password'
import withdrawals from './slices/settings/withdrawals'

import explore from './slices/Explore/explore'

export const store = configureStore({
  reducer: {
    generalMessage,
    modalService,
    meetingData,
    searchUsers,
    miscellaneous,

    signUp,
    login,
    checkUser,
    resetPassword,
    invitee,
    verification,
    socialAuth,

    updateUser,
    interests,
    socials,
    experience,
    education,
    credentials,

    wallet,
    schedule,
    meeting,
    notes,
    broadcast,
    proUserData,

    payment,
    debug,

    skills,
    industries,
    profile,

    notifications,
    password,
    withdrawals,

    explore,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
