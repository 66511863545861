import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader, setCookie } from '@/utils/session'
import { successMessage, errorMessage } from '../utils/generalMessage'
import Router from 'next/router'

import { closeProfileDetails } from '../utils/modalService'
import http from '@/utils/axiosInstance'

interface IUser {
  updateProfileStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateProfileError: string

  userData: any
  userDataStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userDataError: string

  userFromSlug: any
  userFromSlugStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userFromSlugError: string
}

const initialState: IUser = {
  updateProfileStatus: 'idle',
  updateProfileError: '',

  userData: {},
  userDataStatus: 'idle',
  userDataError: '',

  userFromSlug: {},
  userFromSlugStatus: 'idle',
  userFromSlugError: '',
}

export const UpdateProfile = createAsyncThunk(
  'userData/updateProfile',
  async (formData: Object, thunkAPI) => {
    try {
      setAuthorizationHeader()
      const response = await http.post(`${baseUrl}/user/update`, formData)
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(GetUserData())
        if (Router.pathname === '/signup-form') {
          Router.push('/profile')
        }

        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(closeProfileDetails())
        return { ...data, formData }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetUserData = createAsyncThunk('userData/getUserData', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/user`).then(response => {
    setCookie('user_id', response?.data?.user?.id)
    const data = response.data

    if (data.user.external_account) {
      data.user.external_account.provider_data = JSON.parse(
        data.user.external_account.provider_data
      )
    }
    return data
  })
})

export const GetUserBySlug = createAsyncThunk(
  'userData/getUserBySlug',
  async (userSlug: string) => {
    setAuthorizationHeader()
    return await http.get(`${baseUrl}/profile/${userSlug}`).then(response => {
      const data = response.data.data
      return data
    })
  }
)

export const UpdateUser = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(UpdateProfile.fulfilled, state => {
      state.updateProfileStatus = 'succeeded'
      state.updateProfileError = ''
    }),
      builder.addCase(UpdateProfile.pending, state => {
        state.updateProfileStatus = 'pending'
      }),
      builder.addCase(UpdateProfile.rejected, (state, { error }) => {
        state.updateProfileStatus = 'failed'
        state.updateProfileError = error.message
      })

    builder.addCase(GetUserData.fulfilled, (state, { payload }) => {
      state.userDataStatus = 'succeeded'
      state.userDataError = ''
      state.userData = payload.user
    }),
      builder.addCase(GetUserData.pending, state => {
        state.userDataStatus = 'pending'
      }),
      builder.addCase(GetUserData.rejected, (state, { error }) => {
        state.userDataStatus = 'failed'
        state.userDataError = error.message
      })

    builder.addCase(GetUserBySlug.fulfilled, (state, { payload }) => {
      state.userFromSlug = payload
      state.userFromSlugStatus = 'succeeded'
      state.userFromSlugError = ''
    }),
      builder.addCase(GetUserBySlug.pending, state => {
        state.userFromSlugStatus = 'pending'
      }),
      builder.addCase(GetUserBySlug.rejected, (state, { error }) => {
        state.userFromSlugStatus = 'failed'
        state.userFromSlugError = error.message
      })
  },
})

export default UpdateUser.reducer
