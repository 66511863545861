import { AnimatePresence } from 'framer-motion'
import { Teacher, Edit2, Trash } from 'iconsax-react'
import { useState } from 'react'
import S from '../Experience/Experience.module.scss'
import DeleteCredentials from './DeleteCredentials'
import AddCredentials from './AddCredentials'
import { ICred } from '@/redux/slices/userData/credentials'

interface ICredentials {
  credentials: ICred
}

const Credentials = ({ credentials }: ICredentials) => {
  const [show, setShow] = useState<boolean>(false)
  const [showAdd, setShowAdd] = useState<boolean>(false)

  return (
    <>
      <AnimatePresence>
        {show && <DeleteCredentials credentials={credentials} handleModal={() => setShow(false)} />}
      </AnimatePresence>
      <AnimatePresence>
        {showAdd && (
          <AddCredentials
            handleDelete={() => setShow(true)}
            credentials={credentials}
            handleModal={() => setShowAdd(false)}
          />
        )}
      </AnimatePresence>

      <li className={S.list}>
        <div className={S.list_left}>
          <span>
            <Teacher size="28" color="#09937E" variant="Bold" />
          </span>
          <div>
            <h3>{credentials.name}</h3>
            <p>{credentials.issuing_org}</p>
          </div>
        </div>
        <div className={S.list_right}>
          <span className={S.time}>
            {credentials.month} {credentials.year}
          </span>
          <span className={S.icon} role="button" onClick={() => setShowAdd(true)}>
            <Edit2 size="20" color="#b1aec6" />
          </span>
          <span className={S.icon} role="button" onClick={() => setShow(true)}>
            <Trash size="20" color="#b1aec6" />
          </span>
        </div>
      </li>
    </>
  )
}

export default Credentials
