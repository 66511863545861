/* eslint-disable no-unused-vars */
import { setAuthorizationHeader, setCookie, baseUrl, removeCookie } from '@/utils/session'
import axios from 'axios'
import Router from 'next/router'

const RefreshTokenService = async () => {
  setAuthorizationHeader()

  try {
    const response = await axios.post(`${baseUrl}/auth/refresh`)

    let data = response.data
    setCookie('access_token', data.access_token)

    return data.access_token
  } catch (e) {
    // removeCookie('access_token')
    // removeCookie('user_id')
    // removeCookie('user_email')
    // Router.push('/')
  }
}

export default RefreshTokenService
