import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ArrowLeft, CloseCircle, Trash } from 'iconsax-react'

import ClientOnlyPortal from '../ClientPortal'
import S from './Experience.module.scss'

import { Button, Select, Check, Input } from '@/shared'

import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { ISelect, months, years } from '@/utils/mock'
import { AddExperience, UpdateExperience } from '@/redux/slices/userData/experience'
import { useMediaQuery } from '@/hooks'
import { buildOptions, buildYears } from '@/utils'

const AddExperiences = ({
  handleModal,
  newAddition,
  experience,
  handleDelete,
}: {
  handleDelete?: () => void
  handleModal: () => void
  newAddition?: boolean
  experience?: any
}) => {
  const isTablet = useMediaQuery('(max-width: 768px)')
  const dispatch = useAppDispatch()
  const { addExperienceStatus, updateExperienceStatus } = useAppSelector(
    ({ experience }) => experience
  )

  const [disabled, setDisabled] = useState<boolean>(true)
  const [checked, setChecked] = useState<any>(false)
  const [role, setRole] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [startMonth, setStartMonth] = useState<ISelect | null>(null)
  const [endMonth, setEndMonth] = useState<ISelect | null>(null)
  const [startYear, setStartYear] = useState<ISelect | null>(null)
  const [endYear, setEndYear] = useState<ISelect | null>(null)

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  const endYearArray = useMemo(() => {
    const years = buildYears(50, 0)
    if (!startYear) {
      return years
    }
    return years.filter(it => it >= +startYear.value)
  }, [startYear])

  const startMonthsArray = useMemo(() => {
    if (!startYear) {
      return months
    }
    return months.filter(month => {
      // if the experience start this year
      // it can't start after this current month
      if (+startYear.value == currentYear) {
        return +month.value <= currentMonth
      }
      //for any other year, it could be any month
      return months
    })
  }, [currentMonth, currentYear, startYear])

  const endMonthArray = useMemo(() => {
    if (!startYear || !endYear || !startMonth) {
      return months
    }
    return months.filter(month => {
      // if the experience ends this year then
      // it can not end in a future months
      // it cannot end in a month before the start month
      if (+endYear.value == currentYear) {
        return +month.value <= currentMonth && month.value >= startMonth.value
      }
      //for any other year, it could be any month
      return months
    })
  }, [currentMonth, currentYear, endYear, startMonth, startYear])

  useEffect(() => {
    if (!checked) {
      if (role && company && startYear && endYear && startMonth && endMonth) {
        setDisabled(false)
      } else setDisabled(true)
    } else {
      if (role && company && startYear && startMonth) {
        setDisabled(false)
      } else setDisabled(true)
    }
  }, [checked, company, endMonth, endYear, role, startMonth, startYear])

  const handleClick = async e => {
    e.preventDefault()
    if (!newAddition) {
      if (checked) {
        await dispatch(
          UpdateExperience({
            id: experience.id,
            role,
            company,
            start_date: `${startMonth.value}/${startYear.value}`,
            is_present: checked,
          })
        )
      } else {
        await dispatch(
          UpdateExperience({
            id: experience.id,
            role,
            company,
            start_date: `${startMonth.value}/${startYear.value}`,
            end_date: `${endMonth.value}/${endYear.value}`,
            is_present: checked,
          })
        )
      }
    } else {
      checked
        ? dispatch(
            AddExperience({
              role,
              company,
              start_date: `${startMonth.value}/${startYear.value}`,
              is_present: checked,
            })
          )
        : dispatch(
            AddExperience({
              role,
              company,
              start_date: `${startMonth.value}/${startYear.value}`,
              end_date: `${endMonth.value}/${endYear.value}`,
              is_present: checked,
            })
          )
    }

    setTimeout(() => handleModal(), 2000)
  }

  useEffect(() => {
    if (experience && Object.keys(experience).length > 0) {
      setCompany(experience.company)
      setRole(experience.role)
      setChecked(experience.is_present)
      setEndMonth(months.filter(month => month.value === experience?.end_date?.split('/')[0])[0])
      setStartMonth(
        months.filter(month => month.value === experience?.start_date?.split('/')[0])[0]
      )
      setStartYear(years.filter(year => year.value === experience?.start_date?.split('/')[1])[0])
      setEndYear(years.filter(year => year.value === experience?.end_date?.split('/')[1])[0])
    }
  }, [experience])

  return (
    <ClientOnlyPortal selector="#portal">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'tween', duration: 0.25 }}
        className={S.add}>
        <div className={S.add_main}>
          {isTablet ? (
            <div className="bcModal">
              <ArrowLeft size="24" color="#0a0a0a" onClick={handleModal} />
              <h3>Experience</h3>
            </div>
          ) : (
            <header>
              <div>
                <h2>{newAddition ? 'Add an experience' : 'Update Experience'}</h2>
                <p>Add an experience to your profile</p>
              </div>
              <span role="button" onClick={handleModal}>
                <CloseCircle color="#0a0a0a" variant="Outline" />
              </span>
            </header>
          )}

          <form onSubmit={handleClick}>
            {isTablet && !newAddition && (
              <span className={S.icon} role="button" onClick={handleDelete}>
                <Trash size="20" color="#c55261" />
              </span>
            )}

            <Input
              value={role}
              onChange={e => setRole(e.target.value)}
              type={'text'}
              label="Role"
              name="role"
              placeholder={'eg: Front-end developer'}
            />

            <Input
              value={company}
              onChange={e => setCompany(e.target.value)}
              type={'text'}
              label="Company"
              name="company"
              placeholder={'eg: IBM'}
            />

            <div className={S.flex}>
              <Select
                value={startYear}
                setValue={setStartYear}
                label="Start Date"
                name="startYear"
                placeholder="Year"
                options={years}
              />

              <Select
                value={startMonth}
                setValue={setStartMonth}
                label="Month"
                name="startMonth"
                placeholder="Month"
                options={startMonthsArray}
              />
            </div>

            <AnimatePresence>
              {!checked && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  exit={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  className={S.flex}>
                  <Select
                    value={endYear}
                    setValue={setEndYear}
                    label="End Date"
                    name="endYear"
                    disabled={!startYear}
                    placeholder="Year"
                    options={buildOptions(endYearArray)}
                  />
                  <Select
                    value={endMonth}
                    setValue={setEndMonth}
                    label="Month"
                    name="endMonth"
                    disabled={!endYear || !startYear || !startMonth}
                    placeholder="Month"
                    options={endMonthArray}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <Check
              checked={checked}
              name="present"
              label="present"
              onChange={(e: any) => setChecked(e.target.checked)}
            />
            <Button
              type={disabled ? 'disabled' : 'primary'}
              disabled={disabled}
              loading={addExperienceStatus === 'pending' || updateExperienceStatus === 'pending'}
              usage="submit">
              {!newAddition ? 'Save' : 'Add experience'}
            </Button>
          </form>
        </div>
      </motion.section>
    </ClientOnlyPortal>
  )
}

export default AddExperiences
