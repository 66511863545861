import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { GetUserData } from '@/redux/slices/userData/updateUser'
import { getCookie } from '@/utils/session'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

const UserRedirect = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { userData, userDataStatus } = useAppSelector(({ updateUser }) => updateUser)

  // Check User
  const ref = useRef(false)
  useEffect(() => {
    const token = getCookie('access_token', { req: {} })
    if (token && token !== 'undefined') {
      if (Object.keys(userData).length > 0 && !ref.current && !router.query?.redirect) {
        if (userDataStatus !== 'pending' && !userData.profile?.fullname) {
          router.replace('/signup-form')
        } else if (userData.role === 'client') {
          router.replace('/explore')
        } else if (userData.role === 'pro') {
          router.replace('/schedule')
        }
        ref.current = true
      }
    }
  }, [dispatch, router, userData, userDataStatus])

  useEffect(() => {
    dispatch(GetUserData())
  }, [dispatch])

  return null
}

export default UserRedirect
