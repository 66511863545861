import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { successMessage, errorMessage } from '../utils/generalMessage'
import Router from 'next/router'

interface IProfile {
  rateData: {
    video_call_rate: number | null
    voice_call_rate: number | null
    chat_rate: number | null
  }
  rateStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  rateError: string

  updateRateStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateRateError: string

  updateMeetingLengthStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateMeetingLengthError: string

  finalizeProStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  finalizeProError: string
}

const initialState: IProfile = {
  rateStatus: 'idle',
  rateError: '',
  rateData: {
    video_call_rate: 0,
    voice_call_rate: 0,
    chat_rate: 0,
  },

  updateRateStatus: 'idle',
  updateRateError: '',

  updateMeetingLengthStatus: 'idle',
  updateMeetingLengthError: '',

  finalizeProStatus: 'idle',
  finalizeProError: '',
}

export const GetRateData = createAsyncThunk('profile/rates', async (userSlug: string) => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/rate/${userSlug}`).then(response => response.data)
})

export const UpdateMeetingLength = createAsyncThunk(
  'profile/updateMeetingLength',
  async ({ meeting_length }: { meeting_length: number[] }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`${baseUrl}/meeting_length`, {
        meeting_length,
      })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const UpdateRate = createAsyncThunk(
  'profile/updateRate',
  async (
    {
      video_call_rate,
      voice_call_rate,
      chat_rate,
    }: { video_call_rate: number; voice_call_rate: number; chat_rate: number },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch(`${baseUrl}/rate`, {
        video_rate: video_call_rate * 100,
        voice_rate: voice_call_rate * 100,
        chat_rate: chat_rate * 100,
      })
      let data = response.data

      if (response.status < 400) {
        if (Router.pathname !== '/profile') {
          Router.push('/availability')
        }
        // thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const FinalizePro = createAsyncThunk('profile/createSkill', async (_, thunkAPI) => {
  setAuthorizationHeader()
  try {
    const response = await http.post(`${baseUrl}/finalize_pro`)
    let data = response.data

    if (response.status < 400) {
      // Router.push('/profile')
      // thunkAPI.dispatch(successMessage(data.message))
      return { ...data }
    } else {
      thunkAPI.dispatch(errorMessage(data.message))
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e) {
    thunkAPI.dispatch(errorMessage(e.response?.data?.message))
    return thunkAPI.rejectWithValue(e?.response?.data)
  }
})

export const Profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetRateData.fulfilled, (state, { payload }) => {
      state.rateStatus = 'succeeded'
      state.rateError = ''
      state.rateData = payload
    }),
      builder.addCase(GetRateData.pending, state => {
        state.rateStatus = 'pending'
      }),
      builder.addCase(GetRateData.rejected, (state, { error }) => {
        state.rateStatus = 'failed'
        state.rateError = error.message
      })

    builder.addCase(UpdateRate.fulfilled, state => {
      state.updateRateStatus = 'succeeded'
      state.updateRateError = ''
    }),
      builder.addCase(UpdateRate.pending, state => {
        state.updateRateStatus = 'pending'
      }),
      builder.addCase(UpdateRate.rejected, (state, { error }) => {
        state.updateRateStatus = 'failed'
        state.updateRateError = error.message
      })

    builder.addCase(UpdateMeetingLength.fulfilled, state => {
      state.updateMeetingLengthStatus = 'succeeded'
      state.updateMeetingLengthError = ''
    }),
      builder.addCase(UpdateMeetingLength.pending, state => {
        state.updateMeetingLengthStatus = 'pending'
      }),
      builder.addCase(UpdateMeetingLength.rejected, (state, { error }) => {
        state.updateMeetingLengthStatus = 'failed'
        state.updateMeetingLengthError = error.message
      })
  },
})

export default Profile.reducer
