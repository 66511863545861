import { useOutsideAlerter } from '@/hooks'
import { numberToCurrency } from '@/utils'
import { motion } from 'framer-motion'
import React, { useRef } from 'react'
import { Button } from '..'
import S from './Transaction.module.scss'

const Payout = ({
  handleShow,
  transaction,
  amountDisplay,
  date,
  extraInfo
}: {
  handleShow: () => void
  transaction: any
  amountDisplay: string
  date: string
  extraInfo: JSX.Element
}) => {
  const modalRef = useRef(null)
  useOutsideAlerter(modalRef, handleShow)

  return (
    <motion.div
      className={S.transaction_modal}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'tween', duration: 0.25 }}>
      <div className={S.transaction_modal_main} ref={modalRef}>
        <img src="/icon.png" width={100} height={100} alt="" />
        <h2>Transaction Details</h2>
        <ul>
          <li>
            <p>Transaction Ref.:</p>
            <h4>{transaction.reference}</h4>
          </li>
          <li>
            <p>Narration:</p>
            <h4>{transaction.description}</h4>
          </li>
          <li>
            <p>Amount:</p>
            <h4>{amountDisplay}
              {' '}{extraInfo}
            </h4>
          </li>
          <li>
            <p>Payment Type:</p>
            <h4>{transaction.transaction_type}</h4>
          </li>

          <li>
            <p>Date:</p>
            <h4>{date}</h4>
          </li>
        </ul>
        <footer>
          <Button type={'secondary'} onClick={handleShow}>
            Close
          </Button>
          <Button type={'primary'} onClick={() => window.print?.()}>
            Print
          </Button>
        </footer>
      </div>
    </motion.div>
  )
}

export default Payout
