import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Router from 'next/router'
import { baseUrl, setCookie } from '../../../utils/session'
// eslint-disable-next-line no-unused-vars
import { errorMessage, successMessage } from '../utils/generalMessage'

import { authUser } from './userAuth'

interface IUserState {
  data: {}
  isFetching: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string
}

const initialState: IUserState = {
  data: {},
  isFetching: 'idle',
  error: '',
}

export const signupUser = createAsyncThunk('signup/signupUser', async (signup: any, thunkAPI) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/signup`, signup)
    let data = response.data
    if (response.status < 400) {
      Router.push('/verify')
      thunkAPI.dispatch(successMessage(data.message))
      setCookie('user_email', data.user.email)
      thunkAPI.dispatch(authUser())
      return { ...data, signup }
    } else {
      thunkAPI.dispatch(errorMessage(data.message))
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e) {
    thunkAPI.dispatch(errorMessage(e.response?.data?.message))
    return thunkAPI.rejectWithValue(e?.response?.data)
  }
})

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signupUser.fulfilled, (state, { payload }) => {
      state.isFetching = 'succeeded'
      state.data = payload.signup
    }),
      builder.addCase(signupUser.pending, state => {
        state.isFetching = 'pending'
      }),
      builder.addCase(signupUser.rejected, (state, { error }) => {
        state.isFetching = 'failed'
        state.error = error.message
      })
  },
})
export default signupSlice.reducer
