import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
import { successMessage, errorMessage } from '../utils/generalMessage'

interface IPassword {
  updatePasswordStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updatePasswordError: string
}

const initialState: IPassword = {
  updatePasswordStatus: 'idle',
  updatePasswordError: '',
}

export const UpdatePassword = createAsyncThunk(
  'password/updatePassword',
  async (
    {
      new_password,
      new_password_confirmation,
      password,
    }: { new_password: string; new_password_confirmation: string; password: string },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.patch('/setting/password', {
        new_password,
        new_password_confirmation,
        password,
      })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Password = createSlice({
  name: 'password',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(UpdatePassword.fulfilled, state => {
      state.updatePasswordStatus = 'succeeded'
      state.updatePasswordError = ''
    }),
      builder.addCase(UpdatePassword.pending, state => {
        state.updatePasswordStatus = 'pending'
      }),
      builder.addCase(UpdatePassword.rejected, (state, { error }) => {
        state.updatePasswordStatus = 'failed'
        state.updatePasswordError = error.message
      })
  },
})

export default Password.reducer
