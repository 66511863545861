import { store } from '@/redux/store'
import * as Sentry from '@sentry/nextjs'

const captureSentryError = (erorr: Error | string, data: Record<string, any> = {}) => {
  Sentry.withScope(scope => {
    const { meeting, profile, updateUser, meetingData } = store.getState()
    Object.entries(data).forEach(([key, value]) => {
      scope.setTag(key, value)
    })
    scope.setExtras({
      meeting, profile, updateUser, meetingData
    })
    Sentry.captureException(erorr)
  })
}

export default captureSentryError
