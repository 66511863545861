import { capitalizeFirstLetter } from '@/utils'
import http from '@/utils/axiosInstance'
import { setAuthorizationHeader } from '@/utils/session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorMessage } from './generalMessage'

interface IMiscellaneous {
  countriesData: any[]
  countriesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  countriesError: string

  currencyData: any[]
  currencyStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  currencyError: string

  exchageRatesData: Record<string, number>
  exchageRatesStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  exchageRatesError: string

  uploadFileData: string
  uploadFileStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  uploadFileError: string

  searchData: any
  searchValue: string
}

const initialState: IMiscellaneous = {
  countriesData: [],
  countriesStatus: 'idle',
  countriesError: '',

  currencyData: [],
  currencyStatus: 'idle',
  currencyError: '',

  exchageRatesData: {},
  exchageRatesStatus: 'idle',
  exchageRatesError: '',

  uploadFileData: '',
  uploadFileStatus: 'idle',
  uploadFileError: '',

  searchData: { data: [] },
  searchValue: '',
}

export const GetCountries = createAsyncThunk('miscellaneous/GetCountries', async () => {
  setAuthorizationHeader()
  return await http.get('/misc/country').then(response => {
    const arr: { label: string; value: string; code: string }[] = []
    response.data.map(country =>
      arr.push({
        label: capitalizeFirstLetter(country.name),
        value: capitalizeFirstLetter(country.name),
        code: country.code,
      })
    )
    return arr
  })
})

export const GetCurrencies = createAsyncThunk('miscellaneous/GetCurrencies', async () => {
  setAuthorizationHeader()
  return await http.get('/misc/currency').then(response => {
    const arr: { label: string; value: string; code: string }[] = []
    response.data.map(currency =>
      arr.push({
        label: currency.name,
        value: currency.name,
        code: currency.code,
      })
    )
    return arr
  })
})
export const GetExchangeRates = createAsyncThunk('miscellaneous/GetExchangeRates', async () => {
  setAuthorizationHeader()
  return await http.get('/misc/rates').then(response => {
    return response.data.data
  })
})

export const UploadFile = createAsyncThunk(
  'miscellaneous/UploadFile',
  async (
    { upload_type, file }: { upload_type: 'PROFILE' | 'BROADCAST' | 'GENERAL'; file: any },
    thunkAPI
  ) => {
    try {
      const response = await http.post('/misc/upload', { upload_type, file })
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        // thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Miscellaneous = createSlice({
  name: 'miscellaneous',
  initialState,
  reducers: {
    setSearchData: (state, { payload }) => {
      state.searchData = payload
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GetCountries.fulfilled, (state, { payload }) => {
      state.countriesStatus = 'succeeded'
      state.countriesData = payload
    }),
      builder.addCase(GetCountries.pending, state => {
        state.countriesStatus = 'pending'
      }),
      builder.addCase(GetCountries.rejected, (state, { error }) => {
        state.countriesStatus = 'failed'
        state.countriesError = error.message
      })

    builder.addCase(GetCurrencies.fulfilled, (state, { payload }) => {
      state.currencyStatus = 'succeeded'
      state.currencyData = payload
    }),
      builder.addCase(GetCurrencies.pending, state => {
        state.currencyStatus = 'pending'
      }),
      builder.addCase(GetCurrencies.rejected, (state, { error }) => {
        state.currencyStatus = 'failed'
        state.currencyError = error.message
      })

    builder.addCase(GetExchangeRates.fulfilled, (state, { payload }) => {
      state.exchageRatesStatus = 'succeeded'
      state.exchageRatesData = payload
    }),
      builder.addCase(GetExchangeRates.pending, state => {
        state.exchageRatesStatus = 'pending'
      }),
      builder.addCase(GetExchangeRates.rejected, (state, { error }) => {
        state.exchageRatesStatus = 'failed'
        state.exchageRatesError = error.message
      })
  },
})

export default Miscellaneous.reducer

export const { setSearchData, setSearchValue } = Miscellaneous.actions
