import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

export interface IUser {
  createSkillsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createSkillsError: string

  updateSkillsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateSkillsError: string

  skillsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  skillsError: string
  skillsData: any

  userSkillsData: any
  userSkillsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userSkillsError: string
}

const initialState: IUser = {
  createSkillsStatus: 'idle',
  createSkillsError: '',

  updateSkillsStatus: 'idle',
  updateSkillsError: '',

  skillsStatus: 'idle',
  skillsError: '',
  skillsData: [],

  userSkillsStatus: 'idle',
  userSkillsError: '',
  userSkillsData: [],
}

export const UpdateSkill = createAsyncThunk('skills/updateSkill', async (skills: any, thunkAPI) => {
  setAuthorizationHeader()
  try {
    const response = await http.patch(`${baseUrl}/user_skill`, { skills })
    let data = response.data

    if (response.status < 400) {
      thunkAPI.dispatch(GetUserSkills())
      // thunkAPI.dispatch(successMessage('Skills updated successfully'))

      return { ...data }
    } else {
      thunkAPI.dispatch(errorMessage(data.message))
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e) {
    thunkAPI.dispatch(errorMessage(e.response?.data?.message))
    return thunkAPI.rejectWithValue(e?.response?.data)
  }
})

export const CreateSkill = createAsyncThunk(
  'skills/createSkill',
  async ({ name, skills }: { name: string; skills: string[] }, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/skill`, { name })
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(UpdateSkill([...skills, Number(data.data.id)]))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetAllSkills = createAsyncThunk(
  'skills/getAllSkills',
  async ({ keyword, page }: { keyword: string; page: number }) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/skill/?keyword=${keyword}&per_page=100&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const GetUserSkills = createAsyncThunk('skills/getUserSkills', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/user_skill`).then(response => response.data)
})

export const Skills = createSlice({
  name: 'skills',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(GetAllSkills.fulfilled, (state, { payload }) => {
      state.skillsStatus = 'succeeded'
      state.skillsError = ''
      state.skillsData = payload
    }),
      builder.addCase(GetAllSkills.pending, state => {
        state.skillsStatus = 'pending'
      }),
      builder.addCase(GetAllSkills.rejected, (state, { error }) => {
        state.skillsStatus = 'failed'
        state.skillsError = error.message
      })

    builder.addCase(GetUserSkills.fulfilled, (state, { payload }) => {
      state.userSkillsStatus = 'succeeded'
      state.userSkillsError = ''
      state.userSkillsData = payload
    }),
      builder.addCase(GetUserSkills.pending, state => {
        state.userSkillsStatus = 'pending'
      }),
      builder.addCase(GetUserSkills.rejected, (state, { error }) => {
        state.userSkillsStatus = 'failed'
        state.userSkillsError = error.message
      })

    builder.addCase(CreateSkill.fulfilled, state => {
      state.createSkillsStatus = 'succeeded'
      state.createSkillsError = ''
    }),
      builder.addCase(CreateSkill.pending, state => {
        state.createSkillsStatus = 'pending'
      }),
      builder.addCase(CreateSkill.rejected, (state, { error }) => {
        state.createSkillsStatus = 'failed'
        state.createSkillsError = error.message
      })

    builder.addCase(UpdateSkill.fulfilled, state => {
      state.updateSkillsStatus = 'succeeded'
      state.updateSkillsError = ''
    }),
      builder.addCase(UpdateSkill.pending, state => {
        state.updateSkillsStatus = 'pending'
      }),
      builder.addCase(UpdateSkill.rejected, (state, { error }) => {
        state.updateSkillsStatus = 'failed'
        state.updateSkillsError = error.message
      })
  },
})

export default Skills.reducer
