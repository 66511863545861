import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

import {
  CreateIndustries,
  GetAllIndustries,
  GetUserIndustries,
  UpdateIndustries,
} from '@/redux/slices/BecomeAPro/industries'
import { ISelect } from '@/utils/mock'
import { closeProfileIndustries } from '@/redux/slices/utils/modalService'

function useIndustries(page: number = 1) {
  const dispatch = useAppDispatch()
  const [userIndustries, setUserIndustries] = useState<ISelect[]>([])
  const { industriesData, userIndustriesData, createIndustriesStatus } = useAppSelector(({ industries }) => industries)
  const industryArray: { label: string; value: string }[] = useMemo(() => {
    if (industriesData.data) {
      const arr = []
      industriesData.data?.map(skill =>
        arr.push({
          label: skill.name,
          value: skill.id.toString(),
        })
      )

      return arr
    }
  }, [industriesData.data])

  // GET ALL INDUSTRIES
  const ref = useRef(false)
  useEffect(() => {
    if (!ref.current) {
      dispatch(
        GetAllIndustries({
          keyword: '',
          page,
        })
      )
      dispatch(GetUserIndustries())
    }
    return () => {
      ref.current = true
    }
  }, [dispatch, page])

  // USER INDUSTRIES
  useEffect(() => {
    const arr = []
    userIndustriesData.data?.map(skill => {
      arr.push({ label: skill.name, value: skill.id })
    })
    setUserIndustries(arr)
  }, [userIndustriesData])

  // CREATE INDUSTRY IF NOT AVAILABLE
  const createIndustries = (value: string) => {
    const industries = []
    userIndustries.map(industry => industries.push(industry.value))
    dispatch(CreateIndustries({ name: value, industries }))
  }

  const updateIndustries = async () => {
    const industryArr = []
    userIndustries.map(industry => industryArr.push(industry.value))
    await dispatch(UpdateIndustries(industryArr))
    dispatch(closeProfileIndustries())
  }

  return { industryArray, userIndustries, createIndustries, setUserIndustries, updateIndustries, isCreatingIndustry: createIndustriesStatus === 'pending' }
}

export default useIndustries
