import 'stream-chat-react/dist/scss/v2/index.scss'
import 'react-calendar/dist/Calendar.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-tooltip/dist/react-tooltip.css'
import '../scss/index.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import Preloader from '@/layout/preloader'
import { Toast } from '@/shared'

import { Provider } from 'react-redux'
import { store } from '@/redux/store'
import ErrorBoundary from '@/components/Errorboundary'
import { useState } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import Script from 'next/script'

function MyApp({ Component, pageProps }: AppProps) {
  const [client] = useState(() => new QueryClient())
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=6,user-scalable=yes"
        />
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <title>Pickt.</title>
      </Head>
      {/* <Script id="freshdesk">
        {`window.fwSettings={
	'widget_id':150000002762
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() `}
      </Script>
      <Script
        type="text/javascript"
        src="https://widget.freshworks.com/widgets/150000002762.js"
        async
        defer></Script> */}

      <>
        <QueryClientProvider client={client}>
          <Provider store={store}>
            <ErrorBoundary>
              <Toast />
              <Preloader />
              <Component {...pageProps} />
            </ErrorBoundary>
          </Provider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </>
    </>
  )
}

export default MyApp
