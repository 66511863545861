import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, removeCookie } from '@/utils/session'
import { successMessage, errorMessage } from '../utils/generalMessage'
import Router from 'next/router'
import axios from 'axios'

interface IReset {
  sendResetLinkStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  sendResetLinkError: string
  resetPasswordStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  resetPasswordError: string
}

const initialState: IReset = {
  sendResetLinkStatus: 'idle',
  sendResetLinkError: '',
  resetPasswordStatus: 'idle',
  resetPasswordError: '',
}

export const SendResetLink = createAsyncThunk(
  'resetPassword/sendResetLink',
  async (email: Object, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}/password/send-reset-link`, email)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        return { ...data, email }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.error))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const PasswordReset = createAsyncThunk(
  'resetPassword/PasswordReset',
  async (formData: Object, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}/password/reset`, formData)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        Router.push('/signin')
        removeCookie('access_token')
        removeCookie('user_id')
        removeCookie('user_email')
        return { ...data, formData }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const ResetPassword = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(SendResetLink.fulfilled, state => {
      state.sendResetLinkStatus = 'succeeded'
      state.sendResetLinkError = ''
    }),
      builder.addCase(SendResetLink.pending, state => {
        state.sendResetLinkStatus = 'pending'
      }),
      builder.addCase(SendResetLink.rejected, (state, { error }) => {
        state.sendResetLinkStatus = 'failed'
        state.sendResetLinkError = error.message
      })

    builder.addCase(PasswordReset.fulfilled, state => {
      state.resetPasswordStatus = 'succeeded'
      state.resetPasswordError = ''
    }),
      builder.addCase(PasswordReset.pending, state => {
        state.resetPasswordStatus = 'pending'
      }),
      builder.addCase(PasswordReset.rejected, (state, { error }) => {
        state.resetPasswordStatus = 'failed'
        state.resetPasswordError = error.message
      })
  },
})

export default ResetPassword.reducer
