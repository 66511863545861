import http from '@/utils/axiosInstance'
import { setAuthorizationHeader } from '@/utils/session'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Router from 'next/router'
import { successMessage, errorMessage } from '../utils/generalMessage'
import { closeWithdrawalDelete, closeWithdrawalSetup } from '../utils/modalService'

interface IWithdrawals {
  stepOneData: any

  banksData: any
  banksStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  banksError: string

  withdrawalMethodFieldsData: any
  withdrawalMethodFieldsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  withdrawalMethodFieldsError: string

  userWithdrawalMethodData: any
  userWithdrawalMethodStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  userWithdrawalMethodError: string

  createWithdrawalMethodStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createWithdrawalMethodError: string

  updateWithdrawalMethodStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateWithdrawalMethodError: string

  deleteWithdrawalMethodStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  deleteWithdrawalMethodError: string

  defaultWithdrawalMethodStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  defaultWithdrawalMethodError: string

  requestWithdrawalStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  requestWithdrawalError: string
}

const initialState: IWithdrawals = {
  stepOneData: {},

  banksData: [],
  banksStatus: 'idle',
  banksError: '',

  withdrawalMethodFieldsData: [],
  withdrawalMethodFieldsStatus: 'idle',
  withdrawalMethodFieldsError: '',

  userWithdrawalMethodData: [],
  userWithdrawalMethodStatus: 'idle',
  userWithdrawalMethodError: '',

  createWithdrawalMethodStatus: 'idle',
  createWithdrawalMethodError: '',

  updateWithdrawalMethodStatus: 'idle',
  updateWithdrawalMethodError: '',

  deleteWithdrawalMethodStatus: 'idle',
  deleteWithdrawalMethodError: '',

  defaultWithdrawalMethodStatus: 'idle',
  defaultWithdrawalMethodError: '',

  requestWithdrawalStatus: 'idle',
  requestWithdrawalError: '',
}

export const GetWithdrawalMethodFields = createAsyncThunk(
  'withdrawals/GetWithdrawalMethodFields',
  async (
    {
      country_code,
      bank_code,
      currency_code,
    }: {
      country_code: string
      bank_code: string
      currency_code: string
    },
    thunkAPI
  ) => {
    setAuthorizationHeader()

    try {
      const response = await http.get(
        `/setting/withdrawal_fields?country_code=${country_code}&currency_code=${currency_code}&bank_code=${bank_code}`
      )
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(closeWithdrawalSetup())
        return data
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetBanks = createAsyncThunk(
  'withdrawals/GetBanks',
  async ({ country_code }: { country_code: string }) => {
    setAuthorizationHeader()
    return await http.get(`/transaction/banks/${country_code}`).then(response => {
      const arr: { label: string; value: string; code: string }[] = []

      response.data?.data?.map(bank =>
        arr.push({ label: bank.name, value: bank.name, code: bank.code })
      )
      return arr
    })
  }
)

export const RequestWithdrawal = createAsyncThunk(
  'withdrawals/RequestWithdrawal',
  async (
    { amount, withdrawal_method_id }: { amount: number; withdrawal_method_id: number },
    thunkAPI
  ) => {
    setAuthorizationHeader()
    try {
      const response = await http.post('/transaction/withdraw', { amount, withdrawal_method_id })
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        Router.push('/wallet')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetUserWithdrawalMethods = createAsyncThunk(
  'withdrawals/GetUserWithdrawalMethods',
  async (page: number) => {
    setAuthorizationHeader()
    return await http.get(`/setting/withdrawal?page=${page}`).then(response => response.data)
  }
)

export const CreateWithdrawalMethods = createAsyncThunk(
  'withdrawals/CreateWithdrawalMethods',
  async ({ formData, nextRoute }: any, thunkAPI) => {
    try {
      const response = await http.post('/setting/withdrawal', formData)
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Withdrawal Method set successfully'))
        await thunkAPI.dispatch(GetUserWithdrawalMethods(1))
        if (nextRoute) {
          Router.push(nextRoute)
        }
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const UpdateWithdrawalMethod = createAsyncThunk(
  'withdrawals/UpdateWithdrawalMethod',
  async ({ formData, id }: { formData: any; id: number }, thunkAPI) => {
    try {
      const response = await http.patch(`/setting/withdrawal/${id}`, formData)
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteWithdrawalMethod = createAsyncThunk(
  'withdrawals/DeleteWithdrawalMethod',
  async (id: number, thunkAPI) => {
    try {
      const response = await http.delete(`/setting/withdrawal/${id}`)
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(closeWithdrawalDelete())
        thunkAPI.dispatch(GetUserWithdrawalMethods(1))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DefaultWithdrawalMethod = createAsyncThunk(
  'withdrawals/DefaultWithdrawalMethod',
  async (id: number, thunkAPI) => {
    try {
      const response = await http.patch(`/setting/withdrawal_default/${id}`)
      let data = response.data
      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const Withdrawals = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    setStepOneData: (state, { payload }) => {
      state.stepOneData = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GetBanks.fulfilled, (state, { payload }) => {
      state.banksStatus = 'succeeded'
      state.banksData = payload
    }),
      builder.addCase(GetBanks.pending, state => {
        state.banksStatus = 'pending'
      }),
      builder.addCase(GetBanks.rejected, (state, { error }) => {
        state.banksStatus = 'failed'
        state.banksError = error.message
      })

    builder.addCase(GetWithdrawalMethodFields.fulfilled, (state, { payload }) => {
      state.withdrawalMethodFieldsStatus = 'succeeded'
      state.withdrawalMethodFieldsData = payload
    }),
      builder.addCase(GetWithdrawalMethodFields.pending, state => {
        state.withdrawalMethodFieldsStatus = 'pending'
      }),
      builder.addCase(GetWithdrawalMethodFields.rejected, (state, { error }) => {
        state.withdrawalMethodFieldsStatus = 'failed'
        state.withdrawalMethodFieldsError = error.message
      })

    builder.addCase(GetUserWithdrawalMethods.fulfilled, (state, { payload }) => {
      state.userWithdrawalMethodStatus = 'succeeded'
      state.userWithdrawalMethodData = payload
    }),
      builder.addCase(GetUserWithdrawalMethods.pending, state => {
        state.userWithdrawalMethodStatus = 'pending'
      }),
      builder.addCase(GetUserWithdrawalMethods.rejected, (state, { error }) => {
        state.userWithdrawalMethodStatus = 'failed'
        state.userWithdrawalMethodError = error.message
      })

    builder.addCase(CreateWithdrawalMethods.fulfilled, state => {
      state.createWithdrawalMethodStatus = 'succeeded'
    }),
      builder.addCase(CreateWithdrawalMethods.pending, state => {
        state.createWithdrawalMethodStatus = 'pending'
      }),
      builder.addCase(CreateWithdrawalMethods.rejected, (state, { error }) => {
        state.createWithdrawalMethodStatus = 'failed'
        state.createWithdrawalMethodError = error.message
      })

    builder.addCase(UpdateWithdrawalMethod.fulfilled, state => {
      state.updateWithdrawalMethodStatus = 'succeeded'
    }),
      builder.addCase(UpdateWithdrawalMethod.pending, state => {
        state.updateWithdrawalMethodStatus = 'pending'
      }),
      builder.addCase(UpdateWithdrawalMethod.rejected, (state, { error }) => {
        state.updateWithdrawalMethodStatus = 'failed'
        state.updateWithdrawalMethodError = error.message
      })

    builder.addCase(DeleteWithdrawalMethod.fulfilled, state => {
      state.deleteWithdrawalMethodStatus = 'succeeded'
    }),
      builder.addCase(DeleteWithdrawalMethod.pending, state => {
        state.deleteWithdrawalMethodStatus = 'pending'
      }),
      builder.addCase(DeleteWithdrawalMethod.rejected, (state, { error }) => {
        state.deleteWithdrawalMethodStatus = 'failed'
        state.deleteWithdrawalMethodError = error.message
      })

    builder.addCase(DefaultWithdrawalMethod.fulfilled, state => {
      state.defaultWithdrawalMethodStatus = 'succeeded'
    }),
      builder.addCase(DefaultWithdrawalMethod.pending, state => {
        state.defaultWithdrawalMethodStatus = 'pending'
      }),
      builder.addCase(DefaultWithdrawalMethod.rejected, (state, { error }) => {
        state.defaultWithdrawalMethodStatus = 'failed'
        state.defaultWithdrawalMethodError = error.message
      })

    builder.addCase(RequestWithdrawal.fulfilled, state => {
      state.requestWithdrawalStatus = 'succeeded'
    }),
      builder.addCase(RequestWithdrawal.pending, state => {
        state.requestWithdrawalStatus = 'pending'
      }),
      builder.addCase(RequestWithdrawal.rejected, (state, { error }) => {
        state.requestWithdrawalStatus = 'failed'
        state.requestWithdrawalError = error.message
      })
  },
})

export const { setStepOneData } = Withdrawals.actions
export default Withdrawals.reducer
