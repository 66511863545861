import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setAuthorizationHeader } from '@/utils/session'
import http from '@/utils/axiosInstance'
// import Router from 'next/router'
import { successMessage, errorMessage } from '../utils/generalMessage'
import Router from 'next/router'

interface IBroadcast {
  createMeetingBroadcastStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  createMeetingBroadcastError: string

  requestMeetingBroadcastStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  requestMeetingBroadcastError: string

  joinFreeMeetingStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  joinFreeMeetingError: string

  proUserBroadcastsData: any
  proUserBroadcastsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  proUserBroadcastsError: string

  singleBroadcastData: any
  singleBroadcastStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  singleBroadcastError: string
}

const initialState: IBroadcast = {
  createMeetingBroadcastStatus: 'idle',
  createMeetingBroadcastError: '',

  requestMeetingBroadcastStatus: 'idle',
  requestMeetingBroadcastError: '',

  joinFreeMeetingStatus: 'idle',
  joinFreeMeetingError: '',

  proUserBroadcastsData: [],
  proUserBroadcastsStatus: 'idle',
  proUserBroadcastsError: '',

  singleBroadcastData: {},
  singleBroadcastStatus: 'idle',
  singleBroadcastError: '',
}

export const RequestMeetingBroadcast = createAsyncThunk(
  'broadcast/requestMeetingBroadcast',
  async (slug: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/broadcast/request_access/${slug}`)
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage('Broadcast scheduled successfully'))
        // Router.push('/broadcasts')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const CreateMeetingBroadcast = createAsyncThunk(
  'broadcast/createMeetingBroadcast',
  async (formData: any, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post('/broadcast', formData)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage('Broadcast scheduled successfully'))
        // Router.push('/broadcasts')
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const JoinFreeMeeting = createAsyncThunk(
  'broadcast/joinFreeMeeting',
  async (id: number, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`/broadcast/free/${id}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        Router.reload()
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetProUserBroadcasts = createAsyncThunk(
  'broadcast/getUserMeetingHistory',
  async ({ page, userSlug }: { page: number; userSlug: string }) => {
    setAuthorizationHeader()
    return await http
      .get(`/broadcast/pro/${userSlug}?per_page=10&page=${1 * page}`)
      .then(response => response.data)
  }
)

export const GetBroadcastBySlug = createAsyncThunk(
  'broadcast/getBroadcastBySlug',
  async (slug: string) => {
    setAuthorizationHeader()
    return await http.get(`/broadcast/${slug}`).then(response => response.data.data)
  }
)

export const Broadcast = createSlice({
  name: 'Broadcast',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(CreateMeetingBroadcast.fulfilled, state => {
      state.createMeetingBroadcastStatus = 'succeeded'
      state.createMeetingBroadcastError = ''
    }),
      builder.addCase(CreateMeetingBroadcast.pending, state => {
        state.createMeetingBroadcastStatus = 'pending'
      }),
      builder.addCase(CreateMeetingBroadcast.rejected, (state, { error }) => {
        state.createMeetingBroadcastStatus = 'failed'
        state.createMeetingBroadcastError = error.message
      })

    builder.addCase(RequestMeetingBroadcast.fulfilled, state => {
      state.requestMeetingBroadcastStatus = 'succeeded'
      state.requestMeetingBroadcastError = ''
    }),
      builder.addCase(RequestMeetingBroadcast.pending, state => {
        state.requestMeetingBroadcastStatus = 'pending'
      }),
      builder.addCase(RequestMeetingBroadcast.rejected, (state, { error }) => {
        state.requestMeetingBroadcastStatus = 'failed'
        state.requestMeetingBroadcastError = error.message
      })

    builder.addCase(JoinFreeMeeting.fulfilled, state => {
      state.joinFreeMeetingStatus = 'succeeded'
      state.joinFreeMeetingError = ''
    }),
      builder.addCase(JoinFreeMeeting.pending, state => {
        state.joinFreeMeetingStatus = 'pending'
      }),
      builder.addCase(JoinFreeMeeting.rejected, (state, { error }) => {
        state.joinFreeMeetingStatus = 'failed'
        state.joinFreeMeetingError = error.message
      })

    builder.addCase(GetProUserBroadcasts.fulfilled, (state, { payload }) => {
      state.proUserBroadcastsStatus = 'succeeded'
      state.proUserBroadcastsError = ''
      state.proUserBroadcastsData = payload
    }),
      builder.addCase(GetProUserBroadcasts.pending, state => {
        state.proUserBroadcastsStatus = 'pending'
      }),
      builder.addCase(GetProUserBroadcasts.rejected, (state, { error }) => {
        state.proUserBroadcastsStatus = 'failed'
        state.proUserBroadcastsError = error.message
      })

    builder.addCase(GetBroadcastBySlug.fulfilled, (state, { payload }) => {
      state.singleBroadcastStatus = 'succeeded'
      state.singleBroadcastError = ''
      state.singleBroadcastData = payload
    }),
      builder.addCase(GetBroadcastBySlug.pending, state => {
        state.singleBroadcastStatus = 'pending'
      }),
      builder.addCase(GetBroadcastBySlug.rejected, (state, { error }) => {
        state.singleBroadcastStatus = 'failed'
        state.singleBroadcastError = error.message
      })
  },
})

export default Broadcast.reducer
