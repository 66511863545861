import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { errorMessage } from '@/redux/slices/utils/generalMessage'

import {
  Window,
  MessageList,
  MessageInput,
  Thread,
  Channel,
  Chat,
  LoadingIndicator,
} from 'stream-chat-react'
import { StreamChat } from 'stream-chat'
import { ObjectHasData } from '@/utils'
import { ChatProps } from '.'

const ChatComponent = ({ api_key, channel_url }: ChatProps['chatDetails']) => {
  const router = useRouter()
  const dispatch = useAppDispatch()

  const { userData } = useAppSelector(({ updateUser }) => updateUser)

  const [channel, setChannel] = useState(null)

  const client = useMemo(() => {
    return new StreamChat(api_key)
  }, [api_key])

  const ran = useRef(false)

  useEffect(() => {
    if (!ObjectHasData(userData.profile) || !ObjectHasData(userData.external_account)) return
    if (ran.current) return
    ran.current = true

    const user = {
      id: userData.external_account.account_id,
      name: userData.profile.fullname,
      image: userData.profile.profile_picture,
    }

    client.connectUser(user, userData.external_account.provider_data?.token).then(() => {
      const connectChannel = client.channel('messaging', channel_url, {
        name: channel_url,
        image: userData.profile.profile_picture,
      })
      connectChannel.watch().then(() => {
        setChannel(connectChannel)
      })
    })

    const handleConnectionChange = ({ online = false }) => {
      if (!online) return dispatch(errorMessage('connection lost'))
    }
    client.on('connection.changed', handleConnectionChange)
  }, [client, dispatch, channel_url, router, userData])

  useEffect(() => {
    if (!router || !client || !channel) return
    router.events.on('routeChangeStart', async () => {
      if (!client) return
      await client.disconnectUser?.()
    })
  }, [router, client, channel, dispatch])

  if (!client || !channel) return <LoadingIndicator />

  return (
    <Chat client={client}>
      <Channel channel={channel}>
        <Window>
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  )
}

export default ChatComponent
