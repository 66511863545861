import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl, setAuthorizationHeader } from '@/utils/session'
import { errorMessage, successMessage } from '../utils/generalMessage'
import http from '@/utils/axiosInstance'

export interface ICred {
  name: string
  issuing_org: string
  month: string
  year: string
}

interface IUpdate extends ICred {
  id: string
}
interface IUser {
  addCredentialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  addCredentialsError: string

  updateCredentialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateCredentialsError: string

  deleteCredentialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  deleteCredentialsError: string

  getCredentialsData: ICred[]
  getCredentialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  getCredentialsError: string

  getMyCredentialsData: ICred[]
  getMyCredentialsStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  getMyCredentialsError: string

  getCredentialsBySlugData: ICred[]
  getCredentialsBySlugStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  getCredentialsBySlugError: string
}

const initialState: IUser = {
  addCredentialsStatus: 'idle',
  addCredentialsError: '',

  updateCredentialsStatus: 'idle',
  updateCredentialsError: '',

  deleteCredentialsStatus: 'idle',
  deleteCredentialsError: '',

  getCredentialsData: [],
  getCredentialsStatus: 'idle',
  getCredentialsError: '',

  getCredentialsBySlugData: [],
  getCredentialsBySlugStatus: 'idle',
  getCredentialsBySlugError: '',

  getMyCredentialsData: [],
  getMyCredentialsStatus: 'idle',
  getMyCredentialsError: '',
}

export const UpdateCredentials = createAsyncThunk(
  'credentials/updateCredentials',
  async (formData: IUpdate, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/credentials/${formData.id}`, {
        name: formData.name,
        issuing_org: formData.issuing_org,
        month: formData.month,
        year: formData.year,
      })
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetMyCredentials())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const DeleteCredentials = createAsyncThunk(
  'credentials/deleteCredentials',
  async (id: string, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.delete(`${baseUrl}/credentials/${id}`)
      let data = response.data

      if (response.status < 400) {
        thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetMyCredentials())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const AddCredentials = createAsyncThunk(
  'credentials/addCredentials',
  async (formData: ICred, thunkAPI) => {
    setAuthorizationHeader()
    try {
      const response = await http.post(`${baseUrl}/credentials`, formData)
      let data = response.data

      if (response.status < 400) {
        // thunkAPI.dispatch(successMessage(data.message))
        thunkAPI.dispatch(GetMyCredentials())
        return { ...data }
      } else {
        thunkAPI.dispatch(errorMessage(data.message))
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      thunkAPI.dispatch(errorMessage(e.response?.data?.message))
      return thunkAPI.rejectWithValue(e?.response?.data)
    }
  }
)

export const GetCredentials = createAsyncThunk('credentials/getCredentials', async (id: string) => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/credentials/${id}`).then(response => response.data.data)
})

export const GetCredentialsBySlug = createAsyncThunk(
  'credentials/getCredentialsBySlug',
  async (slug: string) => {
    setAuthorizationHeader()
    return await http
      .get(`${baseUrl}/profile/${slug}/credentials`)
      .then(response => response.data.data)
  }
)

export const GetMyCredentials = createAsyncThunk('credentials/getMyCredentials', async () => {
  setAuthorizationHeader()
  return await http.get(`${baseUrl}/credentials`).then(response => response.data.data)
})

export const Credentials = createSlice({
  name: 'credentials',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AddCredentials.fulfilled, state => {
      state.addCredentialsStatus = 'succeeded'
      state.addCredentialsError = ''
    }),
      builder.addCase(AddCredentials.pending, state => {
        state.addCredentialsStatus = 'pending'
      }),
      builder.addCase(AddCredentials.rejected, (state, { error }) => {
        state.addCredentialsStatus = 'failed'
        state.addCredentialsError = error.message
      })

    builder.addCase(UpdateCredentials.fulfilled, state => {
      state.updateCredentialsStatus = 'succeeded'
      state.updateCredentialsError = ''
    }),
      builder.addCase(UpdateCredentials.pending, state => {
        state.updateCredentialsStatus = 'pending'
      }),
      builder.addCase(UpdateCredentials.rejected, (state, { error }) => {
        state.updateCredentialsStatus = 'failed'
        state.updateCredentialsError = error.message
      })

    builder.addCase(DeleteCredentials.fulfilled, state => {
      state.deleteCredentialsStatus = 'succeeded'
      state.deleteCredentialsError = ''
    }),
      builder.addCase(DeleteCredentials.pending, state => {
        state.deleteCredentialsStatus = 'pending'
      }),
      builder.addCase(DeleteCredentials.rejected, (state, { error }) => {
        state.deleteCredentialsStatus = 'failed'
        state.deleteCredentialsError = error.message
      })

    builder.addCase(GetMyCredentials.fulfilled, (state, { payload }) => {
      state.getMyCredentialsStatus = 'succeeded'
      state.getMyCredentialsError = ''
      state.getMyCredentialsData = payload
    }),
      builder.addCase(GetMyCredentials.pending, state => {
        state.getMyCredentialsStatus = 'pending'
      }),
      builder.addCase(GetMyCredentials.rejected, (state, { error }) => {
        state.getMyCredentialsStatus = 'failed'
        state.getMyCredentialsError = error.message
      })

    builder.addCase(GetCredentials.fulfilled, (state, { payload }) => {
      state.getCredentialsStatus = 'succeeded'
      state.getCredentialsError = ''
      state.getCredentialsData = payload
    }),
      builder.addCase(GetCredentials.pending, state => {
        state.getCredentialsStatus = 'pending'
      }),
      builder.addCase(GetCredentials.rejected, (state, { error }) => {
        state.getCredentialsStatus = 'failed'
        state.getCredentialsError = error.message
      })

    builder.addCase(GetCredentialsBySlug.fulfilled, (state, { payload }) => {
      state.getCredentialsBySlugStatus = 'succeeded'
      state.getCredentialsBySlugError = ''
      state.getCredentialsBySlugData = payload
    }),
      builder.addCase(GetCredentialsBySlug.pending, state => {
        state.getCredentialsBySlugStatus = 'pending'
      }),
      builder.addCase(GetCredentialsBySlug.rejected, (state, { error }) => {
        state.getCredentialsBySlugStatus = 'failed'
        state.getCredentialsBySlugError = error.message
      })
  },
})

export default Credentials.reducer
