import { useAppSelector } from '@/redux/hooks'
import { GetExchangeRates } from '@/redux/slices/utils/miscellaneous'
import { ObjectHasData } from '@/utils'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useExchangeRates = () => {
  const { exchageRatesData, exchageRatesStatus } = useAppSelector(
    ({ miscellaneous }) => miscellaneous
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!ObjectHasData(exchageRatesData) && exchageRatesStatus !== 'pending') {
      dispatch(GetExchangeRates())
    }
  }, [dispatch, exchageRatesData, exchageRatesStatus])

  return {
    exchageRatesData,
    isReady: exchageRatesStatus === 'succeeded',
  }
}
