import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { clearMessage } from '@/redux/slices/utils/generalMessage'
import { AnimatePresence, motion } from 'framer-motion'
import S from './Toast.module.scss'
import { CloseCircle } from 'iconsax-react'
import { isDevelopment } from '@/utils'

// Alert Component
const Alerts = ({ message, variant, onClick }) => (
  <motion.div
    initial={{ x: '-100%', opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: '-100%', opacity: 0 }}
    transition={{ type: 'tween', duration: 0.4 }}
    className={S.alerts}>
    <div className={variant === 'error' ? S.error : ''}>
      <div>
        {variant === 'error' ? (
          <svg
            className={S.svg}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="20" fill="#C55261" />
            <path
              d="M29.2392 14.5289C30.04 13.7919 31.265 13.832 32.0308 14.6063L33.1814 15.7696C33.9472 16.5438 33.9869 17.7824 33.2579 18.5921C32.1726 19.7975 30.4564 21.6715 28.3279 23.8862C30.5577 26.2 32.3546 28.1614 33.4768 29.4079C34.2058 30.2176 34.1662 31.4562 33.4004 32.2304L32.2498 33.3937C31.484 34.168 30.259 34.2081 29.4582 33.4711C28.2259 32.3369 26.2871 30.5212 24 28.2681C21.7129 30.5212 19.7741 32.3369 18.5418 33.4711C17.741 34.2081 16.516 34.168 15.7502 33.3937L14.5996 32.2304C13.8338 31.4562 13.7942 30.2176 14.5232 29.4079C15.6454 28.1614 17.4423 26.2 19.6721 23.8862C17.5436 21.6715 15.8274 19.7975 14.7421 18.5921C14.0131 17.7824 14.0528 16.5438 14.8186 15.7696L15.9692 14.6063C16.735 13.832 17.96 13.7919 18.7608 14.5289C19.9536 15.6267 21.8082 17.363 24 19.5165C26.1918 17.363 28.0464 15.6267 29.2392 14.5289Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            className={S.svg}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="20" fill="#04AD68" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M34.1943 15.5488C35.0065 16.1775 35.2432 17.3803 34.7268 18.3073C30.7202 25.4994 27.0745 30.2848 24.9201 32.8462C23.7614 34.224 21.8982 34.4007 20.5843 33.1986C18.7085 31.4824 15.9516 28.5687 13.3133 24.3247C12.8161 23.5249 12.9238 22.4585 13.5434 21.7669L15.132 19.9938C15.8716 19.1684 17.0873 19.1457 17.8332 19.9644C20.46 22.8478 22.4973 25.4624 22.4973 25.4624C22.4973 25.4624 25.491 20.6058 30.1398 14.7174C30.7823 13.9035 31.8908 13.7659 32.6946 14.388L34.1943 15.5488Z"
              fill="white"
            />
          </svg>
        )}
        <span>{message}</span>
      </div>
      <span className={S.btn} role="button" onClick={onClick}>
        <CloseCircle color="#0a0a0a" variant="Outline" />
      </span>
    </div>
  </motion.div>
)
const timeoutDuration = isDevelopment ? 10000 : 3500

const GeneralMessage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { generalMessageState, generalMessage, timeout } = useAppSelector(
    ({ generalMessage }) => generalMessage
  )

  useEffect(() => {
    isDevelopment && generalMessage && console.error(generalMessage)
  }, [generalMessage])

  const [variant, setVariant] = useState<'error' | 'success'>(null)

  const handleClick = useCallback(() => {
    setVariant(null)
    dispatch(clearMessage())
  }, [dispatch])

  useEffect(() => {
    const v = generalMessageState === 'failed' ? 'error' : 'success'
    setVariant(v)

    const timerId = setTimeout(() => {
      handleClick()
    }, timeout ?? timeoutDuration)

    return () => {
      clearTimeout(timerId)
    }
  }, [generalMessageState, dispatch, handleClick, timeout])

  return (
    <>
      <AnimatePresence>
        {generalMessage && (
          <Alerts
            onClick={handleClick}
            message={generalMessage}
            variant={variant}
            key={generalMessage}
          />
        )}
      </AnimatePresence>
    </>
  )
}
export default GeneralMessage
