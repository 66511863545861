import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { ArrowLeft, CloseCircle, Trash } from 'iconsax-react'

import ClientOnlyPortal from '../ClientPortal'
import S from '../Experience/Experience.module.scss'

import { Button, Select, Input } from '@/shared'

import { months, years } from '@/utils/mock'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { UpdateCredentials, AddCredentials } from '@/redux/slices/userData/credentials'
import { useMediaQuery } from '@/hooks'
import { ObjectHasData } from '@/utils'

const Add = ({
  handleModal,
  newAddition,
  credentials,
  handleDelete,
}: {
  handleModal: () => void
  handleDelete?: () => void
  newAddition?: boolean
  credentials?: any
}) => {
  const dispatch = useAppDispatch()
  const isTablet = useMediaQuery('(max-width: 768px)')
  const { addCredentialsStatus, updateCredentialsStatus } = useAppSelector(
    ({ credentials }) => credentials
  )

  const [disabled, setDisabled] = useState<boolean>(true)
  const [name, setName] = useState<string>('')
  const [org, setOrg] = useState<string>('')

  const [month, setMonth] = useState<{ label: string; value: string } | null>(null)
  const [year, setYear] = useState<{ label: string; value: string } | null>(null)

  useEffect(() => {
    if (name && org && year && month) {
      setDisabled(false)
    } else setDisabled(true)
  }, [org, name, month, year])

  const handleClick = e => {
    e.preventDefault()
    if (!newAddition) {
      dispatch(
        UpdateCredentials({
          id: credentials.id,
          name,
          issuing_org: org,
          month: month.label,
          year: year.label,
        })
      )
    } else {
      dispatch(
        AddCredentials({
          name,
          issuing_org: org,
          month: month.label,
          year: year.label,
        })
      )
    }

    setTimeout(() => handleModal(), 2000)
  }

  useEffect(() => {
    if (ObjectHasData(credentials)) {
      setName(credentials.name)
      setOrg(credentials.issuing_org)
      setMonth(months.filter(month => month.label === credentials.month)[0])
      setYear(years.filter(year => year.label.toString() === credentials.year.toString())[0])
    }
  }, [credentials])

  return (
    <ClientOnlyPortal selector="#portal">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'tween', duration: 0.25 }}
        className={S.add}>
        <div className={S.add_main}>
          {isTablet ? (
            <div className="bcModal">
              <ArrowLeft size="24" color="#0a0a0a" onClick={handleModal} />
              <h3>Credentials</h3>
            </div>
          ) : (
            <header>
              <div>
                <h2>{newAddition ? 'Add credentials' : 'Update credentials'}</h2>
                <p>Add credentials to your profile</p>
              </div>
              <span role="button" onClick={handleModal}>
                <CloseCircle color="#0a0a0a" variant="Outline" />
              </span>
            </header>
          )}

          <form onSubmit={handleClick}>
            {isTablet && !newAddition && (
              <span className={S.icon} role="button" onClick={handleDelete}>
                <Trash size="20" color="#c55261" />
              </span>
            )}

            <Input
              onChange={e => setName(e.target.value)}
              value={name}
              label="Name"
              type="text"
              name="name"
              placeholder="Name"
            />

            <Input
              onChange={e => setOrg(e.target.value)}
              value={org}
              label="Issuing Organization"
              type="text"
              name="school"
              placeholder="Issuing Organization"
            />

            <div className={S.flex}>
              <Select
                value={month}
                setValue={setMonth}
                label="Issue Date"
                name="month"
                placeholder="Month"
                options={months}
              />

              <Select
                value={year}
                setValue={setYear}
                label="Year"
                name="year"
                placeholder="Year"
                options={years}
              />
            </div>

            <Button
              type={disabled ? 'disabled' : 'primary'}
              disabled={disabled}
              loading={addCredentialsStatus === 'pending' || updateCredentialsStatus === 'pending'}
              usage="submit">
              {!newAddition ? 'Save' : 'Add credentials'}
            </Button>
          </form>
        </div>
      </motion.section>
    </ClientOnlyPortal>
  )
}

export default Add
